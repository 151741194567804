<template>
    <button class="shadow chip"
            :style="{color: active ? textColorActive : textColor, backgroundColor: active ? bgColor : 'transparent', borderColor: bgColor}">
        {{text}}
        <span v-if="showDelete" class="material-symbols-rounded"
              :style="{color: textColor}">clear</span>
    </button>
</template>

<script>
    export default {
        name: "chip-button",
        props: {
            text: String,
            active: Boolean,
            bgColor: String,
            textColor: String,
            textColorActive: String,
            showDelete: Boolean
        }
    }
</script>

<style scoped>
    .chip {
        border: 2px solid;
        border-radius: 3em;
        padding: 0.4em 1em;
        display: grid;
        grid-template-columns: min-content min-content;
        align-items: center;
    }
</style>
