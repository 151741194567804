<template>
    <div class="main-content">
        <toggle-button v-if="taggingMode === 1" class="challenge-label" text2="" :text1="challengeLabel"
                       icon2="" icon1="refresh" text-color="var(--text-dark)"
                       bg-color="var(--secondary-color)" v-on:click="refreshChallenge"
                       :state="true"></toggle-button>
        <autocomplete v-if="taggingMode === 0" class="tag-ac" :all-tags="allTags"
                      @tagselected="setSelectedTag"></autocomplete>
        <div class="ros-e">
            <embed width="100%" height="100%" :src="require(`../../../public/img/assets/ROS-E.svg`)"
                   type="image/svg+xml"/>
        </div>
        <video-clip :video-size="videoSize" :label="challengeLabel" :tags="currentTags"></video-clip>
    </div>
</template>

<script>
    import VideoClip from "../VideoClip";
    import ToggleButton from "../ToggleButton";
    import APIConnector from "../../scripts/APIConnector";
    import Autocomplete from "../Autocomplete";

    export default {
        name: 'RecordPage',
        components: {Autocomplete, VideoClip, ToggleButton},
        data() {
            return {
                challengeLabel: '-',
                allTags: [],
                videoSize: 300,
                apiConn: new APIConnector(),
                taggingMode: 0,
                currentTags: []
            }
        },
        created() {
            this.getAllTags();
            this.taggingMode = this.$store.state.taggingMode;
            this.unsubscribeStoreMutations = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateTaggingMode') {
                    this.taggingMode = state.taggingMode;
                }
            });
        },
        methods: {
            async getAllTags() {
                this.apiConn.getAllTags().then(tags => {
                    // console.log('new tags from api:');
                    this.allTags = tags;
                    this.challengeLabel = this.getNewChallenge();
                });
            },
            getNewChallenge() {
                return this.allTags[Math.floor(Math.random() * (this.allTags.length))].text;
            },
            refreshChallenge() {
                this.challengeLabel = this.getNewChallenge();
                // console.log(this.challengeLabel);
            },
            setSelectedTag(tag) {
                this.currentTags.push(tag);
                console.log('selected tags: ');
                console.log(this.currentTags);
            }
        },
        unmounted() {
            this.unsubscribeStoreMutations();
        }
    }
</script>

<style scoped>
    .challenge-label {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1;
        margin: 2em auto;
        text-align: center;
        max-width: 200px;
        border-radius: 4px;
        background-color: var(--secondary-color);
        color: var(--text-dark);
        padding: 0.6em;
    }

    .tag-ac {
        position: fixed;
        left: auto;
        right: auto;
        z-index: 1;
        margin: 2em auto;
        padding: 0.6em;
    }

    .ros-e {
        margin: 1em;
        /*resize: both;
        width: auto;
        height: 90%;*/
        position: relative;
        padding-bottom: calc(70% * 4.3 / 6);
    }

    .ros-e embed {
        position: absolute;
        min-height: 260px;
    }

    /*.clips {
        display: grid;
        grid-template-areas: 'video-clips main-video';
        grid-template-columns: auto min-content;
        grid-template-rows: auto;
        grid-gap: 20px;
    }*/

</style>
