<template>
    <button id="start-session" class="shadow"
            :class="icon1 !== undefined && text1 !== undefined ? 'button-w-icon' : text1 !== undefined ? 'text-button' : 'icon-button'"
            :style="{color: textColor, backgroundColor: bgColor}">
       <!-- <embed v-if="l_state === true && icon1" class="playback-icons" :src="l_icon1"
               :style="{fill: l_textColor, width: l_size, height: l_size}"/>-->
        <span v-if="l_state && icon1 !== undefined" class="material-symbols-rounded"
              :style="{color: textColor, height: size}">{{icon1}}</span>
        <span v-if="!l_state && icon2 !== undefined" class="material-symbols-rounded"
              :style="{color: textColor, height: size}">{{icon2}}</span>
        <span v-if="l_state && text1 !== undefined" >{{text1}}</span>
        <span v-if="!l_state && text2 !== undefined" >{{text2}}</span>
    </button>
</template>

<script>

    export default {
        name: "toggle-button",
        props: {
            text1: {
                type: String,
                default: ''
            },
            text2: {
                type: String,
                default: ''
            },
            icon1: {
                type: String,
                default: 'play_arrow'
            },
            icon2:  {
                type: String,
                default: 'pause'
            },
            state: Boolean,
            bgColor:  {
                type: String,
                default: 'var(--secondary-color)'
            },
            textColor:  {
                type: String,
                default: 'var(--background)'
            },
            size:  {
                type: String,
                default: '22px'
            },
        },
        watch: {
            state: function(newVal) {
                this.setState(newVal);
            }
        },
        created() {
            this.setState(this.state);
        },
        methods: {
            setState: function(state){
                if(state === undefined){
                    this.l_state = false;
                } else {
                    this.l_state = state;
                }
            }
        }
    }
</script>

<style scoped>
    .icon-button {
        padding: 0.4em;
    }

    .icon-button * {
        pointer-events: none;
    }

    .button-w-icon {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-template-rows: min-content;
        grid-gap: 6px;
        align-items: center;
        padding: 0.6em;
    }

    .text-button {
        width: min-content;
        height: min-content;
        margin: auto;
    }

    .playback-icons {
        cursor: pointer;
        margin: auto;
    }
</style>
