import ImageNetClassList from './imagenet.js';
import Kinetics600ClassList from './kinetics600.js';

export default class Prediction {
    constructor(label, percent, forClip) {
        this.forClip = forClip;
        this.name = label;
        this.probability = percent;
    }

    static kinetics600ClassesTopK(classProbabilities, k) {
        if (!k) {
            k = 5;
        }
        const probs = Array.from(classProbabilities);
        const probsIndices = probs.map(
            function (prob, index) {
                return [prob, index];
            }
        );
        const sorted = probsIndices.sort(
            function (a, b) {
                if (a[0] < b[0]) {
                    return -1;
                }
                if (a[0] > b[0]) {
                    return 1;
                }
                return 0;
            }
        ).reverse();
        return sorted.slice(0, k).map(function (probIndex) {
            const iClass = Kinetics600ClassList.classObj[probIndex[1]];
            return {
                id: iClass[0],
                index: parseInt(probIndex[1], 10),
                name: iClass[1].replace(/_/g, ' '),
                probability: probIndex[0]
            };
        });
    }

    /**
     * Utility function to post-process Resnet50 output. Find top k ImageNet classes with highest probability.
     */
    static imagenetClassesTopK(classProbabilities, k) {
        if (!k) {
            k = 5;
        }
        const probs = Array.from(classProbabilities);
        const probsIndices = probs.map(
            function (prob, index) {
                return [prob, index];
            }
        );
        const sorted = probsIndices.sort(
            function (a, b) {
                if (a[0] < b[0]) {
                    return -1;
                }
                if (a[0] > b[0]) {
                    return 1;
                }
                return 0;
            }
        ).reverse();
        return sorted.slice(0, k).map(function (probIndex) {
            const iClass = ImageNetClassList.classObj[probIndex[1]];
            return {
                id: iClass[0],
                index: parseInt(probIndex[1], 10),
                name: iClass[1].replace(/_/g, ' '),
                probability: probIndex[0]
            };
        });
    }

}

