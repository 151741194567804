<template>
    <div class="mini-clip">
        <chip-button v-if="mode === 0" :text="clipData.label || '-'" :active="true"
                     bg-color="var(--primary-color)" text-color-active="var(--text-dark)"></chip-button>
        <chip-button v-if="mode === 1" :text="clipData.reward.toString() || '-'" :active="true"
                     bg-color="var(--text-dark)" text-color-active="var(--background)"></chip-button>
        <div class="video-mini-container" :class="paused ? 'paused' : ''" v-on:click="togglePlay">
            <video class="video-mini" :ref="'v-' + (clipData.id || 0)" :width="size" :height="size" v-on:ended="stopVideo"></video>
            <div class="overlay"></div>
            <span class="timestamp">{{clipData.createdAt || '-'}}</span>
        </div>
        <div class="save-buttons">
            <toggle-button v-if="!useTextButtons" icon1="save"
                           :state="true" text-color="var(--background)" size="16px"
                           bg-color="var(--secondary-color)" v-on:click="saveClipLocal"></toggle-button>
            <toggle-button v-if="useTextButtons" icon1="save" text1="lokal speichern"
                           :state="true" text-color="var(--background)"
                           bg-color="var(--secondary-color)" v-on:click="saveClipLocal"></toggle-button>
            <toggle-button v-if="!useTextButtons" icon1="cloud_upload"
                           :state="true" text-color="var(--background)" size="16px"
                           bg-color="var(--secondary-color)" v-on:click="saveClipServer"></toggle-button>
            <toggle-button v-if="useTextButtons" icon1="cloud_upload" text1="zum Server senden"
                           :state="true" text-color="var(--background)"
                           bg-color="var(--secondary-color)" v-on:click="saveClipServer"></toggle-button>
        </div>
    </div>

</template>

<script>
    import ChipButton from "./ChipButton";
    import ToggleButton from "./ToggleButton";

    export default {
        name: "mini-clip",
        components: {ToggleButton, ChipButton},
        props: {
            clipData: Object,
            textButtons: Boolean
        },
        data() {
            return {
                video: {},
                paused: true,
                size: Number,
                unsubscribeStoreMutations: () => {},
                mode: 0,
                useTextButtons: false
            }
        },
        created() {
            if(this.textButtons === undefined){
                this.useTextButtons = false;
            }else {
                this.useTextButtons = this.textButtons;
            }
            this.mode = this.$store.state.trainMode;
            this.size = this.$store.state.imageSize;
            // watch store
            this.unsubscribeStoreMutations = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateTrainMode') {
                    this.mode = state.trainMode;
                }
            });
        },
        mounted() {
            if (this.clipData !== undefined && this.$refs['v-' + this.clipData.id] !== undefined) {
                this.video = this.$refs['v-' + this.clipData.id];
                this.paused = this.video.paused;
                // console.log('clip from video: ' + this.textButtons);
                // console.log(this.clipData.video);
                if (this.clipData.video !== undefined ){ // empty {} check && Object.keys(this.clipData.video).length !== 0) {
                    this.video.src = URL.createObjectURL(this.clipData.video);
                    console.log('Set video src for ' + this.clipData.video);
                    console.log(this.video.src);
                }else {
                    console.log(this.clipData);
                    console.log('Error: could not set video src for ' + this.clipData.id);
                }
            } else {
                console.log('Error: could not set video src for ' + this.clipData.id);
            }
        },
        methods: {
            togglePlay: function () {
                this.paused = this.video.paused || this.video.ended;
                if (this.paused) {
                    this.video.play();
                    this.paused = false;
                } else {
                    this.video.pause();
                    this.paused = true;
                }
                // console.log('toggle ' + this.clipData.id + ' paused: ' + this.paused);
            },
            stopVideo: function () {
                if (!this.video.paused || !this.video.ended) {
                    this.video.pause();
                }
                this.paused = true;
                // console.log('video ended: ' + this.paused);
            },
            saveClipLocal: function () {
                if(this.clipData.video !== undefined){
                    let filename = 'session_' + this.clipData.label + '_' + this.clipData.createdAt;
                    const blob = this.clipData.video;
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    URL.revokeObjectURL(link.href);
                }else {
                    console.log('video not found');
                }

            },
            saveClipServer: function () {
                console.log('save clip server');
            }
        },
        unmounted() {
            this.unsubscribeStoreMutations();
        }
    }
</script>

<style scoped>
    .mini-clip {
        padding: 0.4em;
        display: grid;
        justify-items: center;
    }

    .video-mini-container {
        border-radius: 4px;
        padding: 0.2em;
        position: relative;
    }

    .video-mini {
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .paused .overlay {
        opacity: 0.4;
    }

    .overlay {
        position: absolute;
        top: 0.2em;
        left: 0.2em;
        opacity: 0;
        width: 98%;
        height: 96%;
        transition: .5s ease;
        background-color: var(--secondary-color);
        background-image: url("../../public/img/assets/play.svg");
        background-size: 64px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .timestamp {
        position: absolute;
        bottom: 0.6em;
        left: 0.3em;
        font-size: 10pt;
        background-color: rgba(var(--primary-rgb), 0.6);
        color: var(--text-dark);
        padding: 0 0.2em;
    }

    .save-buttons {
        display: grid;
        grid-template-columns: min-content min-content;
    }
</style>
