<template>
    <div class="main-content styled-scrollbars">
        <div class="gallery-actions shadow">
            <toggle-button text1="neue Aufnahme" text-color="var(--text-dark)" icon1="radio_button_checked"
                           bg-color="var(--primary-color)" v-on:click="newRecording"
                           :state="true"></toggle-button>
        </div>
        <div class="gallery-grid">
            <gallery-clip v-for="clip in clips" v-bind:key="clip.id" :clip-data="clip"
                          :use-text-buttons="true" :db-handler="dbHandler"></gallery-clip>
            <p v-if="clips.length === 0">Keine gespeicherten Clips vorhanden.</p>
        </div>

    </div>
</template>

<script>
    import ToggleButton from "../ToggleButton";
    import DatabaseHandler from "../../scripts/DatabaseHandler";
    import GalleryClip from "../GalleryClip";

    export default {
        name: "GalleryPage",
        components: {ToggleButton, GalleryClip},
        data() {
            return {
                clips: [],
                dbHandler: {}
            }
        },
        async created() {
            this.dbHandler = new DatabaseHandler('training_data', this.$store);
            this.clips = await this.dbHandler.getClips();
        },
        methods: {
            newRecording: function () {
                this.$router.push('/record');
            }
        }
    }
</script>

<style scoped>
    .main-content {
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: auto;
        overflow-x: hidden;
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
        grid-auto-rows: min-content;
        grid-gap: 20px;
        align-items: start;
        border-radius: 4px;
        padding: 1em;
        margin: 3em 1em 1em;
    }

    .gallery-actions {
        background-color: var(--background);
        position: fixed;
        padding: 0.4em;
        z-index: 2;
        right: 48px;
        left: 60px;
        top: 0;
        display: grid;
        justify-items: center;
    }
</style>
