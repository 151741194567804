import {imageDataToTensor} from "./DataUtils";
import {Tensor} from "onnxruntime-web/lib/onnxjs/tensor";

/**
 * Prepares Data for the MoViNet A0 Base Model
 */
export default class MoViNetLoader {
    constructor(modelUrl, dbHandler) {
        this.dbHandler = dbHandler;
        this.frameSize = 172;
        this.modelUrl = modelUrl;
    }

    // video input shape: shape [1 (batch), 1 (frames), 172, 172, 3]
    // for MoViNet A0 Base -> 5 fps
    // split into frames, save states and pass them separate
    async loadVideo() {
        console.log('converting video to tensor');
        const start = performance.now();
        // frames = np.split(video, video.shape[1], axis=1)
        this.dbHandler.getVideoFrameDataArray().then(clip => {
            let videoInputTensor = [];
            if (clip !== undefined) {
                // Input shape: [1, 1, 172, 172, 3]
                let frameArray = [];
                clip.forEach(frame => {
                    frameArray.push(imageDataToTensor(frame.imgArray, this.frameSize));
                });
                videoInputTensor.push(frameArray);
                videoInputTensor = new Tensor([videoInputTensor.length, this.frameSize, this.frameSize, 3], "float32", videoInputTensor);
            }
            console.log('conversion took: ' + (performance.now() - start) + 'ms');
            return videoInputTensor;
        });
    }
}
