<template>
    <div class="main">
        <div class="app-bar">
            <h1>
                <router-link to="/">S &centerdot; A &centerdot; M</router-link>
            </h1>
        </div>
        <toggle-button v-if="updateExists" icon1="cloud_upload" text1="Seite aktualisieren"
                       :state="true" text-color="var(--background)" size="16px"
                       bg-color="var(--secondary-color)" v-on:click="refreshApp"></toggle-button>
        <!-- route outlet -->
        <!-- component matched by the route will render here -->
        <router-view></router-view>
        <div class="footer"></div>
    </div>
</template>

<script>

    import ToggleButton from "./components/ToggleButton";
    import {RouterLink, RouterView} from "vue-router";

    export default {
        name: 'App',
        components: {ToggleButton, RouterLink, RouterView},
        data() {
            return {
                refreshing: false,
                worker: null,
                updateExists: false
            };
        },

        created() {
            // Listen for swUpdated event and display refresh snackbar as required.
            document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
            // Refresh all open app tabs when a new service worker is installed.
            navigator.serviceWorker &&
            navigator.serviceWorker.addEventListener(
                //triggered by registration.claim
                "controllerchange",
                () => {
                    if (this.refreshing) return;
                    this.refreshing = true;
                    console.log("controllerchange triggered, -> auto refresh!!");
                    window.location.reload();
                }
            );
        },

        methods: {
            showRefreshUI(e) {
                // Display a button inviting the user to refresh/reload the app due
                // to an app update being available.
                // The new service worker is installed, but not yet active.
                // Store the ServiceWorkerRegistration instance for later use.
                if (e.detail) {
                    this.worker = e.detail;
                    this.updateExists = true;
                    console.log("Showing refresh button.");
                    console.log(this.worker);
                } else {
                    console.warn("No worker data found!");
                }
            },
            refreshApp() {
                console.log("skipWaiting started");
                console.log(this.worker);
                // Handle a user tap on the update app button.
                this.updateExists = false;
                // Protect against missing registration.waiting.
                if (!this.worker) {
                    console.warn("No worker data found when trying to refresh!");
                    return;
                }
                this.worker.postMessage({ type: "SKIP_WAITING" });
                console.log("skipWaiting finished");
            }
        }
    }
</script>

<style>
    :root {
        --primary-color: #f5b342;
        --primary-rgb: 245, 179, 66;
        --secondary-color: #7cbcbe;
        --error-color: #ef7c6f;
        --success-color: #69a258;
        --background: #f7eedf;
        --background-dark: #e1d8c9;
        --scrollbar-background: #d2e9e7;
        --text-dark: #5b2323;

        --loader-duration: 3s;
    }

    body {
        margin: 0;
        padding: 0;
        background-color: var(--background);
        font-family: 'Fascinate', "Lucida Console", "Courier New", monospace;
        overflow: hidden;
    }

    .styled-scrollbars {
        /* Foreground, Background */
        scrollbar-color: var(--secondary-color) var(--scrollbar-background);
    }

    .styled-scrollbars::-webkit-scrollbar-thumb { /* Foreground */
        background: var(--secondary-color);
        border-radius: 4px;
    }

    .styled-scrollbars::-webkit-scrollbar-track { /* Background */
        background: var(--scrollbar-background);
    }

    h1 {
        margin: 0.2em;
        font-size: clamp(1.8em, 4vw, 2em);
        font-family: 'Fascinate', "Lucida Console", "Courier New", monospace;
    }

    h2 {
        font-size: clamp(1em, 3vw, 1.6em);
        font-family: 'Fascinate', "Lucida Console", "Courier New", monospace;
    }

    p {
        margin: 0.1em;
        font-size: clamp(0.6em, 2vw, 1em);
        color: var(--text-dark);
        line-height: 1.5em;
        font-family: 'Fascinate', "Lucida Console", "Courier New", monospace;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
    }

    button {
        cursor: pointer;
        border: none;
        border-radius: 4px;
        margin: 0.2em;
        padding: 0.6em 1em;
        white-space: nowrap;
        font-size: clamp(0.8em, 1vw, 1em);
        text-align: center;
    }

    button:hover {
        cursor: pointer;
    }

    .hidden {
        display: none;
    }

    .shadow {
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .error-box {
        border-radius: 4px;
        background-color: var(--error-color);
        color: var(--text-dark);
        padding: 0.6em;
    }

</style>

<style scoped>
    .main {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-areas: 'header main-content footer';
        grid-template-columns: 60px auto 30px;
        grid-template-rows: auto;
    }

    .main-content {
        grid-area: main-content;
        overflow-y: auto;
    }

    .app-bar {
        grid-area: header;
        background-color: var(--primary-color);
        z-index: 1;
    }

    .app-bar h1 {
        color: var(--text-dark);
        text-align: center;
        padding: 0.6em 0;
        line-height: 0.8em;
        word-break: break-all;
        margin: auto;
        width: 0.5em;
    }

    .app-bar a {
        text-decoration: none;
        color: var(--text-dark);
    }

    .footer {
        grid-area: footer;
        background-color: var(--secondary-color);
        z-index: 1;
    }

</style>
