import dateFormat from "dateformat";

export default class ClipData {
    constructor(video, tags, label, reward, url) {
        this.id = Date.now();
        this.video = video;
        this.tags = tags;
        this.previewUrl = url;
        this.label = label;
        this.reward = reward;
        this.createdAt = dateFormat(new Date(), "yyyy-mm-dd_hh:MM:ss");
        this.sendToServer = false;
    }
}
