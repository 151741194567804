<template>
    <div class="actions main-content">
        <div class="intro">
            <h1>S.A.M</h1>
            <h2>Situation Awareness Module</h2>
            <p>Mit dieser App können Traingsbeispiele gesammelt werden, mit denen ein Künstliches Neuronales Netz lernt,
                die aktuelle Situation eintuschätzen. Dieses Netz kann dann z.B. von einem Roboter wie ROS-E verwendet
                werden, um eine passende Aktion wie "Licht einschalten", "Tür öffnen" oder "Notfall melden"
                auszuwählen.</p>
            <br/>
            <!--<p><b>Wähle eine Quelle für die Kamera:</b></p>-->
        </div>
        <!--<div class="train-mode radio-group">
            <input type="radio" id="mode1" name="train-mode-group" v-on:click="setTrainMode(0)" :checked="mode === 0"/>
            <label for="mode1">Label-Modus für Situationen</label>
            <input type="radio" id="mode2" name="train-mode-group" v-on:click="setTrainMode(1)" :checked="mode === 1"/>
            <label for="mode2">Belohnungs-Modus für Aktionen</label>
        </div>
        <div class="desc">
            <p v-if="mode === 0">In diesem Modus ist das <b>Ziel, gute Beispiele für Situationen zu sammeln</b> und
                diese mit
                dem richtigen Label zu speichern. ROS-E gibt für jeden aufgenommenen Clip die Situation an, die sie
                erkannt hat. Wenn die erkannte Situation nicht am besten passt, darfst <b>Du die Passende auswählen</b>.
            </p>
            <p v-if="mode === 1">In diesem Modus ist das <b>Ziel, ROS-E durch Belohnung/Bestrafung zu zeigen, welche
                Aktion in einer Situation passend oder unpassend ist.</b> ROS-E wählt eine Aktion aus, die zur aktuellen
                Situation passt und Du darfst mehr oder weniger Kekse vergeben.</p>
        </div>
        <div class="runner-mode radio-group">
            <input type="radio" id="runmode1" name="runner-mode-group" v-on:click="setRunnerMode(0)"
                   :checked="runnerMode === 0"/>
            <label for="runmode1">ResNet50 für ImageNet-Daten</label>
            <input type="radio" id="runmode2" name="runner-mode-group" v-on:click="setRunnerMode(1)"
                   :checked="runnerMode === 1"/>
            <label for="runmode2">MoViNet für Kinetics600</label>
        </div>
        <div class="desc">
            <p v-if="runnerMode === 0">Das ResNet50 ist ein Netz, das <b>ein einzelnes Bild</b> untersucht.
                Dieses hier ist darauf trainiert, <b>10000 verschiednene Objekte</b> aus dem ImageNet-Datenset zu unterscheiden.
            </p>
            <p v-if="runnerMode === 1">Das MoViNet ist ein Netz, das <b>Videos</b> als aufeinanderfolgende Bilder untersucht.
                Es ist darauf trainiert, <b>600 verschiedene Aktivitäten</b> aus dem Kinetics600-Datenset zu unterscheiden.
            </p>
        </div>-->
        <!--<div class="runner-mode radio-group">
            <input type="radio" id="runmode1" name="runner-mode-group" v-on:click="setRunnerMode(0)"
                   :checked="runnerMode === 0"/>
            <label for="runmode1">Kamera deines Gerätes</label>
            <input type="radio" id="runmode2" name="runner-mode-group" v-on:click="setRunnerMode(1)"
                   :checked="runnerMode === 1"/>
            <label for="runmode2">Kamera von ROS-E</label>
        </div>
        <div class="desc">
            <p v-if="runnerMode === 0">In diesem Modus kannst Du <b>Video-Clips aufnehmen</b>,
                um die Situationserkennung zu verbessern. Dir wird eine <b>Situation vorgegeben</b>, die du nachstellen kannst.
            </p>
            <p v-if="runnerMode === 1">In diesem Modus kannst Du <b>Video-Clips</b> direkt mit ROS-E aufnehmen.
                Für jeden Clip kannst Du sehen, welche <b>Situation sie erkannt hat</b> und wie sie reagiert.
            </p>
        </div>-->
        <div class="tagging-mode radio-group">
            <input type="radio" id="runmode1" name="runner-mode-group" v-on:click="setTaggingMode(0)"
                   :checked="taggingMode === 0"/>
            <label for="runmode1">Situation vor Aufnahme wählen</label>
            <input type="radio" id="runmode2" name="runner-mode-group" v-on:click="setTaggingMode(1)"
                   :checked="taggingMode === 1"/>
            <label for="runmode2">zufällige Situation erhalten</label>
        </div>
        <div class="desc">
            <p v-if="taggingMode === 0">In diesem Modus kannst Du die <b>Situation wählen, bevor Du die Aufnahme</b>
                startest. Im Anschluss kannst du immer noch andere Beschreibungen wählen, die auch der besser passen.
            </p>
            <p v-if="taggingMode === 1">In diesem Modus wird Dir <b>eine zufällige Situation vorgeschlagen</b>, die du
                anschließend nachstellen kannst. Dieser Modus ist als kleines Spiel gedacht, wenn Du dich nicht
                entscheiden kannst, was Du noch aufnehmen könntest. Im Anschluss kannst du immer noch andere
                Beschreibungen wählen, die auch der besser passen.
            </p>
        </div>
        <div class="daten">
            <!--<p>Wenn Du ein Beispiel speichern möchtest, kannst Du es auf Dein Gerät herunterladen und entscheiden,
                ob Du es uns zur Verfügung stellen möchtest, um ROS-E zu trainieren. [Datenschutzerklärung]</p>-->
            <p>Du kannst für jede Aufhname entscheiden, ob Du sie uns zur Vefügung stellen möchtest. Dazu kannst Du sie
                im Anschluss hochladen. [Datenschutzerklärung]</p>
        </div>
        <div class="buttons">
            <toggle-button text1="" text2="Aufnahme-Session starten" icon1=""
                           icon2="play_arrow" text-color="var(--background)"
                           bg-color="var(--secondary-color)" v-on:click="startSession"
                           :state="false"></toggle-button>
        </div>
    </div>
</template>

<script>
    import ToggleButton from "../ToggleButton";

    export default {
        name: "IntroSettingsPage",
        components: {ToggleButton},
        data() {
            return {
                sessionStarted: false,
                mode: 0,
                runnerMode: 0,
                taggingMode: 0,
                unsubscribeStoreMutations: () => {
                },
            }
        },
        async created() {
            this.mode = this.$store.state.trainMode;
            this.runnerMode = this.$store.state.runnerMode;
            this.taggingMode = this.$store.state.taggingMode;
            this.sessionStarted = this.$store.state.sessionStarted;
            // watch store
            this.unsubscribeStoreMutations = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateTrainMode') {
                    this.mode = state.trainMode;
                } else if (mutation.type === 'updateRunnerMode') {
                    this.runnerMode = state.runnerMode;
                }else if (mutation.type === 'updateTaggingMode') {
                    this.taggingMode = state.taggingMode;
                }else if (mutation.type === 'toggleSession') {
                    this.sessionStarted = state.sessionStarted;
                }
            });
        },
        methods: {
            startSession: function () {
                this.$store.commit('toggleSession', true);
                this.$router.push('/record');
            },
            /*setTrainMode: function (mode) {
                this.$store.commit('updateTrainMode', mode);
            },
            setRunnerMode: function (mode) {
                this.$store.commit('updateRunnerMode', mode);
            },*/
            setTaggingMode: function (mode) {
                this.$store.commit('updateTaggingMode', mode);
            },
            unmounted() {
                this.unsubscribeStoreMutations();
            }
        }
    }
</script>

<style scoped>
    .actions {
        width: 100%;
        height: 100%;
        display: grid;
        justify-items: center;
        justify-self: center;
        grid-template-rows: min-content min-content min-content auto;
        text-align: center;
    }

    .intro {
        max-width: 700px;
        padding: 1em 1em 0.3em;
        width: 80%;
        color: var(--text-dark);
    }

    .intro h1 {
        font-size: 3em;
        color: var(--primary-color);
    }

    .radio-group {
        margin: auto;
        padding: 0.4em 1em 1em;
        display: grid;
        grid-template-rows: min-content min-content;
        align-items: center;
    }

    .desc, .daten {
        max-width: 700px;
        padding: 1em;
    }

    .desc {
        background-color: rgba(var(--primary-rgb), 0.2);
        border-radius: 4px;
    }

    .buttons {
        margin: auto;
        padding-bottom: 2em;
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    label {
        position: relative;
        margin-right: 1em;
        padding-left: 2em;
        padding-right: 1em;
        line-height: 2;
        cursor: pointer;
        color: var(--text-dark);
    }

    label:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 0.3em;
        left: 0;
        display: block;
        width: 1.4em;
        height: 1.4em;
        border: 2px solid var(--primary-color);
        border-radius: 0.25em;
        z-index: -1;
    }

    input[type="radio"] + label::before {
        border-radius: 1em;
    }

    /* Checked */
    input[type="radio"]:checked + label {
        padding-left: 1em;
        color: var(--text-dark);
    }

    input[type="radio"]:checked + label:before {
        top: 0;
        width: 100%;
        height: 2em;
        background: var(--primary-color);
    }

    /* Transition */
    label, label::before {
        -webkit-transition: 0.25s all ease;
        -o-transition: 0.25s all ease;
        transition: 0.25s all ease;
    }

</style>
