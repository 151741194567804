class SAMTag {
    constructor(tag_id, text) {
        this.tag_id = tag_id;
        this.text = text;
    }
}

/*class SAMVideo{
    constructor(vid_id, name, tags) {
        this.vid_id = vid_id;
        this.name = name;
        this.tags = tags;
    }
}*/

export default class APIConnector {

    constructor() {
        this.apiBaseURL = process.env.VUE_APP_SAM_DATA_SERVER_ADDRESS;
    }

    async getAllTags() {
        if (this.apiBaseURL !== undefined) {
            let res = await fetch(this.apiBaseURL + '/all_tags', {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            });
            let response = await res.json();
            // console.log(response);
            // a non-200 response code
            if (!res.ok) {
                return [];
            } else {
                let tags = [];
                response['tags'].forEach((t) => {
                    tags.push(new SAMTag(t['tag_id'], t['text']));
                });
                // console.log('created tags:');
                // console.log(tags);
                return tags;
            }
        } else {
            console.log('Could not connect to: ' + this.apiBaseURL);
            return [];
        }
    }

    async addNewTag(tag) {
        let newTag = new SAMTag('', tag);
        let json_tag = JSON.stringify(newTag);
        // console.log(json_tag);
        let res = await fetch(this.apiBaseURL + '/add_tag', {
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            body: json_tag
        });
        // a non-200 response code
        if (!res.ok) {
            return undefined;
        } else {
            // console.log(createdTag);
            return await res.json();
        }
    }

    async uploadVideo(video) {
        // console.log(video);
        let formData = new FormData();
        let filename = 'session_' + video.label + '_' + video.createdAt; // todo: check video.label
        formData.append('vid_name', filename);
        formData.append('vid_tags', JSON.stringify([].concat('{ "tag_id": 1, "text":" ' + video.tags + '"}')));
        formData.append('vid_file', video.video, filename);
        // console.log(formData);
        let res = await fetch(this.apiBaseURL + '/upload_video', {
            method: 'POST',
            // don't set content type!!!!!
            body: formData
        });
        // await res.json();
        // a non-200 response code
        // console.log(res.statusCode);
        return res.ok;
    }
}
