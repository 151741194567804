export function imageDataToTensor(data, size) {
    // 1a. Extract the R, G, and B channels from the data
    const [R, G, B] = [[], [], []];
    for (let i = 0; i < data.length; i += 4) {
        R.push(data[i]);
        G.push(data[i + 1]);
        B.push(data[i + 2]);
        // 2. skip data[i + 3] thus filtering out the alpha channel
    }
    // 1b. concatenate RGB ~= transpose [224, 224, 3] -> [3, 224, 224]
    const transposedData = R.concat(G).concat(B);

    // 3. convert to float32
    let i, l = transposedData.length; // length, we need this for the loop
    const float32Data = new Float32Array(3 * size * size); // create the Float32Array for output
    for (i = 0; i < l; i++) {
        float32Data[i] = transposedData[i] / 255.0; // convert to float
    }
    // return new Tensor("float32", float32Data, dims);
    return float32Data;
}
