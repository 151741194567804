import Localbase from 'localbase';

export default class DatabaseHandler {
    constructor(dbName, store) {
        this.databaseName = dbName;
        this.store = store;
        this.db = new Localbase(this.databaseName);
        this.clips = [];
        this.currentClip = undefined;
        this.predictions = [];
        this.clipCol = 'clips';
        this.predCol = 'predictions';
        this.vidFrameCol = 'video_frames';
        this.audFrameCol = 'audio_frames';
        this.frameID = 1283992385;
    }

    async getClips() {
        await this.db.collection(this.clipCol).orderBy('createdAt', 'desc').get() // .limit(this.store.state.queueSize + 1)
            .then(clips => {
                // return clips except current
                // let end = 1;
                // if (this.store.state.queueSize + 1 > clips.length) {
                //     end = clips.length;
                // } else {
                //     end = this.store.state.queueSize + 1;
                // }
                // console.log('got clips from 1 to ' + end);
                // console.log(clips);
                clips.forEach((c, index) => {
                    c.tags = JSON.parse(c.tags);
                    clips[index] = c;
                });
                this.clips = clips; //.slice(1, end);
            });
        return this.clips;
    }

    async getCurrentClip() {
        let clips = await this.db.collection(this.clipCol).orderBy('createdAt', 'desc').limit(1).get().catch(() => {
            return undefined;
        });
        if (clips.length > 0) {
            let c = clips[0];
            c.tags = JSON.parse(c.tags);
            this.currentClip = c;
        }
        console.log('current clip from db:');
        console.log(this.currentClip);
        return this.currentClip;
    }

    /*getCurrentVideoTag(){
        console.log('tag collection: ' + this.db.collection(this.tagCol));
        if(this.db.collection(this.tagCol) !== undefined){
            this.db.collection(this.tagCol).get().then(tags => {
                if(tags.length > 0){
                    this.currentVideoTag = tags[0].tag;
                    console.log('new video tag: ' + this.currentVideoTag);
                }
            });
        }
        return this.currentVideoTag;
    }*/

    async getPredictions(forClip) {
        return await this.db.collection(this.predCol).doc({forClip: forClip}).get().catch(() => {
            return undefined;
        });
        // console.log('predictions from db');
        // console.log(pred);
    }


    async addToQueue(clip) {
        console.log(clip);
        await this.db.collection(this.clipCol).add({
            id: clip.id,
            video: clip.video,
            tags: JSON.stringify(clip.tags),
            previewUrl: clip.previewUrl,
            label: clip.label,
            reward: clip.reward,
            createdAt: clip.createdAt,
            sendToServer: clip.sendToServer
        });

        if (this.clips.length >= this.store.state.queueSize) {
            this.clips.shift();
        }
        this.clips.push(clip);
        this.currentClip = clip;
    }

    async addVideoFrameData(frames) {
        console.log('saving video frames:');
        // console.log(frames);
        for (let i = 0; i < frames.length; i++) {
            await this.db.collection(this.vidFrameCol).doc({forClip: this.frameID, index: i}).set({
                forClip: this.frameID,
                index: i,
                imgArray: frames[i]
            }).catch(() => {
                console.log('Could not set video frame data!');
                this.db.collection(this.vidFrameCol).add({
                    forClip: this.frameID,
                    index: i,
                    imgArray: frames[i]
                }).catch(() => {
                    console.log('Could not add video frame data!');
                });
            });
        }
    }

    async addAudioFrameData(frames) {
        console.log('saving audio frames:');
        console.log(frames);
        if (frames !== undefined) {
            let slices = this.createAudioChunks(frames);
            for (let i = 0; i < slices.length; i++) {
                this.db.collection(this.audFrameCol).doc({forClip: this.frameID, index: i}).set({
                    forClip: this.frameID,
                    index: i,
                    audioArray: slices[i]
                }).catch(() => {
                    console.log('Could not set audio frame data!');
                    this.db.collection(this.audFrameCol).add({
                        forClip: this.frameID,
                        index: i,
                        audioArray: slices[i]
                    }).catch(() => {
                        console.log('Could not add audio frame data!');
                    });
                });
            }
        } else {
            console.log('Could not add audio frame data!');
        }
    }

    createAudioChunks(frames) {
        // console.log(frames);
        // console.log('duration: ' + frames.duration);
        if (frames.duration > 1){
            let slices = [];
            let sliceCount = Math.ceil(frames.duration);
            let sliceLength = frames.sampleRate;
            // console.log('slices: ' + sliceCount);
            for (let i = 0; i < sliceCount; i++) {
                let begin = i * sliceLength;
                let slice = this.sliceAudioData(frames, begin, begin + sliceLength);
                if(slice !== undefined){
                    console.log(slice);
                    slices.push(slice);
                }else{
                    for (let channel = 0; channel < frames.numberOfChannels; channel++) {
                        // This gives us the actual array that contains the data
                        const nowBuffering = frames.getChannelData(channel);
                        for (let j = i; j < i + 1; j++) {
                            nowBuffering[i] = 0;
                        }
                        slices.push(nowBuffering);
                    }
                    console.log('audio slice could not be created');
                }
            }
            return slices;
        }else{
            return frames;
        }
    }

    sliceAudioData(buffer, startOffset, endOffset) {
        let channels = buffer.numberOfChannels;
        let rate = buffer.sampleRate;

        if (startOffset < 0) {
            startOffset = 0;
        }

        if (endOffset > buffer.length) {
            endOffset = buffer.length;
        }

        let frameCount = endOffset - startOffset;
        let newArrayBuffer;
        let audioContext = new (window.AudioContext || window.webkitAudioContext);

        try {
            newArrayBuffer = audioContext.createBuffer(channels, endOffset - startOffset, rate);
            let anotherArray = new Float32Array(frameCount);
            let offset = 0;

            for (let channel = 0; channel < channels; channel++) {
                buffer.copyFromChannel(anotherArray, channel, startOffset);
                newArrayBuffer.copyToChannel(anotherArray, channel, offset);
            }
        } catch (e) {
            console.log('could not splice audio: ' + e);
            return undefined;
        }
        return newArrayBuffer;
    }

    async getVideoFrameData(index) {
        let frame = await this.db.collection(this.vidFrameCol).doc({
            forClip: this.frameID,
            index: index
        }).get().catch(() => {
            console.log('could not load video frame data');
        });
        // console.log('current video frames:');
        // console.log(frame);
        return frame;
    }

    // get all frames for the last video
    async getVideoFrameDataArray() {
        let frames = await this.db.collection(this.vidFrameCol).get().catch(() => {
            console.log('could not load video frame data');
        });
        console.log('current video frames:');
        console.log(frames);
        return frames;
    }

    async getAudioFrameData() {
        let frame = await this.db.collection(this.audFrameCol).doc({
            forClip: this.frameID,
            index: 0
        }).get().catch(() => {
            console.log('could not load audio frame data');
        });
        // console.log('current audio frames:');
        // console.log(frame);
        return frame;
    }


    /*async updateCurrentVideo(stream){
        console.log('old video tag: ' + this.currentVideoTag);
        if (this.currentVideoTag !== undefined){
            await this.db.collection(this.tagCol).doc({ id: 1 }).update({
                id: 1,
                tag: stream
            });
            console.log('updated video');
        }else{
            await this.db.collection(this.tagCol).doc({ id: 1 }).add({
                id: 1,
                tag: stream
            });
            console.log('added video');
        }
        this.currentVideoTag = stream;
    }*/

    updateClipData(clip) {
        this.db.collection(this.clipCol).doc({id: clip.id}).update({
            id: clip.id,
            video: clip.video,
            tags: JSON.stringify(clip.tags),
            previewUrl: clip.previewUrl,
            label: clip.label,
            reward: clip.reward,
            createdAt: clip.createdAt,
            sendToServer: clip.sendToServer
        }).catch(() => {
            this.db.collection(this.clipCol).add({
                id: clip.id,
                video: clip.video,
                tags: JSON.stringify(clip.tags),
                previewUrl: clip.previewUrl,
                label: clip.label,
                reward: clip.reward,
                createdAt: clip.createdAt,
                sendToServer: clip.sendToServer
            });
        });
    }

    deleteClip(clip) {
        this.db.collection(this.clipCol).doc({ id: clip.id }).delete().catch(() => {
            return 'clip not deleted!';
        });
        return 'clip deleted';
    }

    async updatePredictions(preds, forClip) {
        await this.db.collection(this.predCol).doc({forClip: forClip}).update({
            forClip: forClip,
            predictions: preds
        }).catch(async () => {
            await this.db.collection(this.predCol).add({
                forClip: forClip,
                predictions: preds
            });
        });
        this.predictions = preds;
    }
}
