<template>
    <div class="results">
        <div class="info">
            <p>Hier siehst Du, welche Situation ROS-E erkannt oder welche Aktion sie ausgewählt hat.</p>
            <br/>
            <p v-if="runnerLoading && !runnerLoaded" class="color-info">Netz wird geladen ...</p>
            <p v-if="resultsLoading" class="color-run">Ich analysiere die Situation ...</p>
            <p v-if="errorMessage !== ''" class="color-warn">Fehler: {{errorMessage}}</p>
            <!--<div class="thinking">
                <div v-if="resultsLoading || !runnerLoaded" class="loader">
                    <svg viewBox="0 0 80 80">
                        <circle cx="40" cy="40" r="32"></circle>
                    </svg>
                </div>
                <div class="ros-e">
                    <embed v-if="resultsLoading || !runnerLoaded" width="100%" height="100%"
                           :src="require(`@/assets/ROS-E-thinking.svg`)" type="image/svg+xml"/>
                    <embed v-else width="100%" height="100%" :src="require(`@/assets/ROS-E.svg`)" type="image/svg+xml"/>
                </div>
            </div>-->
        </div>
        <div class="desc">
            <p>Falls eine andere Situation besser passt, kannst Du sie auswählen.</p>
            <!--<p v-if="mode === 1">Wie viele Kekse verdient ROS-E für diese Aktion?</p>-->
        </div>
        <div class="predictions">
            <div v-for="(p, index) in predictions" v-bind:key="index" class="prediction">
                <p>{{index + 1}}</p>
                <ChipButton :text="p.name" text-color="var(--text-dark)" :active="rightSelected.name === p.name"
                            bg-color="var(--secondary-color)" text-color-active="var(--background)"
                            v-on:click="chooseRight(p)"></ChipButton>
                <p style="white-space: nowrap"> mit {{p.probability}}%</p>
            </div>
            <div class="prediction">
                <p>></p>
                <dropdown-button :options="classes" @selected="chooseRight"></dropdown-button>
            </div>
        </div>
        <!--<div v-if="mode === 1" class="reward">
            <div class="cookie shadow" v-for="(c, index) in cookies" v-bind:key="index"
                 v-on:click="chooseReward(index)">
                <span>{{index}}</span>
                <span class="material-symbols-rounded" style="color: var(--text-dark)">{{c}}</span>
            </div>
        </div>-->
        <img v-if="mode === 0" class="current preview shadow" :src="currentPreview"/>
        <mini-clip v-if="mode === 1" class="current" :clip-data="currentClip" :text-buttons="true"></mini-clip>
    </div>
</template>

<script>
    import Prediction from "../scripts/prediction_models/Prediction.js";
    import ChipButton from "./ChipButton";
    import DropdownButton from "./DropdownButton";
    import ImageNetClassList from '../scripts/prediction_models/imagenet.js';
    import DatabaseHandler from "../scripts/DatabaseHandler";
    import MiniClip from "./MiniClip";
    import ONNXRunner from "../scripts/prediction_models/ONNXRunner";
    // import ClipData from "../scripts/ClipData";
    import TFJSRunner from "../scripts/prediction_models/TFJSRunner";
    import Kinetics600ClassList from "../scripts/prediction_models/kinetics600";
    import ResNet50Loader from "../scripts/prediction_models/ResNet50Loader";
    import MoViNetLoader from "../scripts/prediction_models/MoViNetLoader";

    export default {
        name: "results",
        components: {MiniClip, DropdownButton, ChipButton},
        data() {
            return {
                lastRunData: {},
                predictions: [],
                unsubscribeNewPrediction: () => {
                },
                rightSelected: 0,
                cookies: ['cookie', 'cookie', 'cookie', 'cookie', 'cookie'],
                mode: 0,
                runnerMode: 0,
                classes: [],
                dbHandler: {},
                onnxRunner: {},
                renet50Loader: {},
                movinetA0bLoader: {},
                tfjsRunner: {},
                runnerLoading: false,
                runnerLoaded: false,
                currentClip: {},
                currentPreview: {},
                resultsLoading: false,
                errorMessage: ''
            }
        },
        created() {
            this.mode = this.$store.state.trainMode;
            this.runnerMode = this.$store.state.runnerMode;
            this.initPredictionsEmpty();
            this.dbHandler = new DatabaseHandler('training_data', this.$store);
            // watch store
            this.renet50Loader = new ResNet50Loader('model/resnet50_8.onnx', this.dbHandler);
            this.movinetA0bLoader = new MoViNetLoader('model/movinetA0b.onnx', this.dbHandler);
            this.unsubscribeNewPrediction = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateTrainMode') {
                    this.mode = state.trainMode;
                } else if (mutation.type === 'updateRunnerMode') {
                    this.runnerMode = state.runnerMode;
                }
            })
        },
        async mounted() {
            await this.initVideo().then(async () => {
                if (this.runnerMode === 0) {
                    this.initONNXRunner(this.renet50Loader);
                    this.dbHandler.getCurrentClip().then((clip) => {
                        console.log(clip);
                        this.currentPreview = clip.previewUrl;
                        console.log(this.currentPreview);
                    });
                } else if (this.runnerMode === 1) {
                    this.initONNXRunner(this.movinetA0bLoader);
                } else {
                    this.initTFJSRunner();
                }
            });
        },
        methods: {
            async initVideo() {
                let clip = await this.dbHandler.getCurrentClip();
                // console.log('clip from db:');
                // console.log(clip);
                if (clip !== undefined) {
                    this.currentClip = clip;
                    console.log('set current clip: ' + clip.id);
                } else {
                    console.log('no current clip found');
                    this.runnerLoading = false;
                    this.errorMessage = 'Kein aktueller Clip gefunden!';
                }
                // console.log('current clip:');
                // console.log(this.currentClip);
            },
            initONNXRunner(loader) {
                // init model
                this.runnerLoading = true;
                this.onnxRunner = new ONNXRunner(loader);
                this.onnxRunner.initSession().then((success) => {
                    if (success) {
                        this.runnerLoaded = true;
                        this.runnerLoading = false;
                        if ((this.currentClip.id + 60000) < Date.now()) {
                            console.log('getting old clip data from db');
                            this.initPredictionsDB();
                        } else {
                            console.log('getting new prediction for clip');
                            this.getPrediction();
                        }
                    } else {
                        this.runnerLoading = false;
                        this.errorMessage = 'Netz konnte nicht geladen werden!';
                    }
                });
            },
            initTFJSRunner: function () {
                this.runnerLoading = true;
                this.tfjsRunner = new TFJSRunner('model/movinet-tfjs/model.json', this.dbHandler);
                this.tfjsRunner.loadModel().then((success) => {
                    if (success) {
                        this.runnerLoaded = true;
                        this.runnerLoading = false;
                        if ((this.currentClip.id + 120000) < Date.now()) {
                            console.log('getting old clip data from db');
                            this.initPredictionsDB();
                        } else {
                            console.log('getting new prediction for clip');
                            this.getPrediction();
                        }
                    } else {
                        this.runnerLoading = false;
                        this.errorMessage = 'Netz konnte nicht geladen werden!';
                    }
                });
            },
            initPredictionsDB: function () {
                this.predictions = [];
                this.dbHandler.getPredictions(this.currentClip.id).then((pred) => {
                    // console.log(pred);
                    if (pred === undefined || pred.predictions.length === 0) {
                        this.initPredictionsEmpty();
                        // console.log('no predictions found');
                    } else {
                        this.lastRunData = pred.predictions;
                        this.generatePredictions();
                        this.fillOptions();
                    }
                });
            },
            initPredictionsEmpty: function () {
                this.predictions = [];
                for (let i = 0; i < 5; i++) {
                    this.predictions.push(new Prediction('-', 0, -1));
                    this.classes.push(new Prediction('-', 0, -1));
                }
            },
            getPrediction: async function () {
                if (this.runnerLoaded && this.currentClip.video !== undefined) {
                    this.resultsLoading = true;
                    // Run model with Tensor inputs and get the result.
                    let outputData;
                    if (this.runnerMode === 0) {
                        outputData = await this.onnxRunner.runTestImage();
                    } else if (this.runnerMode === 1) {
                        outputData = await this.onnxRunner.runNextClip();
                    } else {
                        outputData = await this.tfjsRunner.runNextClip();
                    }
                    if (outputData !== undefined) {
                        // console.log(outputData);
                        await this.dbHandler.updatePredictions(outputData, this.currentClip.id);
                        this.lastRunData = outputData;
                        this.generatePredictions();
                        if (this.classes.length <= 5) {
                            this.fillOptions();
                        }
                    } else {
                        this.errorMessage = 'Kein Ergebnis vom Netz erhalten!';
                    }
                    this.resultsLoading = false;
                    // this.$store.commit('updatePredictions', outputData);
                } else {
                    console.log("couldn't get prediction! runner loaded: " + this.runnerLoaded + " clip loaded: " + (this.currentClip.video !== undefined));
                }
            },
            generatePredictions: function () {
                this.predictions = [];
                let outputClasses = [];
                if (!this.lastRunData || this.lastRunData.length === 0) {
                    const empty = [];
                    for (let i = 0; i < 5; i++) {
                        empty.push(new Prediction('-', 0));
                    }
                    outputClasses = empty;
                } else {
                    if (this.runnerMode === 0) {
                        outputClasses = Prediction.imagenetClassesTopK(this.lastRunData, 5);
                    } else {
                        outputClasses = Prediction.kinetics600ClassesTopK(this.lastRunData, 5);
                    }
                }
                // console.log(this.lastRunData);
                // console.log(outputClasses);
                for (let i of [0, 1, 2, 3, 4]) {
                    this.predictions.push(new Prediction(outputClasses[i].name, Math.round(100 * outputClasses[i].probability), this.currentClip.id));
                }
                // set default
                this.chooseRight(this.predictions[0]);
            },
            fillOptions: function () {
                this.classes = [];
                let classList = [];
                if (this.runnerMode === 0) {
                    classList = ImageNetClassList.classObj;
                } else {
                    classList = Kinetics600ClassList.classObj;
                }
                Object.values(classList).forEach((value) => {
                        let c = new Prediction(value[1].replace(/_/g, ' '), -1, this.currentClip.id);
                        this.classes.push(c);
                    }
                );
            },
            chooseRight: function (predClass) {
                this.rightSelected = predClass;
                if (this.currentClip.video !== undefined) {
                    this.currentClip.label = predClass.name;
                    this.dbHandler.updateClipData(this.currentClip);
                }
            },
            chooseReward: function (index) {
                if (this.currentClip.video !== undefined) {
                    this.currentClip.reward = index;
                    this.dbHandler.updateClipData(this.currentClip);
                }
            }
        },
        unmounted() {
            this.unsubscribeNewPrediction();
        }
    }
</script>

<style scoped>
    .results {
        grid-area: results;
        padding: 0 2em;
        min-width: 20vw;
        height: auto;
        display: grid;
        grid-template-areas: 'info desc desc current' 'info predictions reward current';
        grid-template-columns: auto auto auto auto;
        grid-template-rows: min-content auto;
        grid-gap: 20px;
    }

    .current {
        grid-area: current;
    }

    .info {
        grid-area: info;
        max-width: 400px;
    }

    .reward {
        grid-area: reward;
        display: grid;
        grid-auto-columns: min-content min-content;
        grid-gap: 10px;
    }

    .predictions {
        grid-area: predictions;
    }

    .preview {
        border-radius: 4px;
    }

    .color-warn {
        color: var(--error-color)
    }

    .color-info {
        color: var(--secondary-color)
    }

    .color-run {
        color: var(--primary-color)
    }

    .cookie {
        margin: auto;
        padding: 0.4em 0.8em;
        background-color: var(--primary-color);
        border-radius: 4em;
        display: grid;
        grid-template-columns: min-content min-content;
        align-items: center;
        cursor: pointer;
        grid-gap: 4px;
    }

    .prediction {
        display: grid;
        grid-template-columns: min-content min-content min-content;
        align-items: center;
        grid-gap: 20px;
    }

    .thinking {
        position: relative;
    }


    .ros-e {
        margin: 1em;
        position: relative;
        padding-bottom: calc(40% * 4.3 / 6);
    }

    .ros-e embed {
        position: absolute;
        min-height: 180px;
    }

    .loader {
        display: inline-block;
        margin: 0 16px;
    }

    .loader {
        width: 44px;
        height: 44px;
        position: relative;
    }

    .loader:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        background: var(--primary-color);
        top: 37px;
        left: 19px;
        transform: translate(-18px, -18px);
        animation: dotRect var(--loader-duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }

    .loader svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    .loader svg circle {
        fill: none;
        stroke: var(--text-dark);
        stroke-width: 10px;
        stroke-linejoin: round;
        stroke-linecap: round;
    }

    .loader svg circle {
        stroke-dasharray: 150;
        stroke-dashoffset: 75;
        animation: pathCircle var(--loader-duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }

    @keyframes pathCircle {
        25% {
            stroke-dashoffset: 125;
        }
        50% {
            stroke-dashoffset: 175;
        }
        75% {
            stroke-dashoffset: 225;
        }
        100% {
            stroke-dashoffset: 275;
        }
    }


</style>
