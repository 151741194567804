<template>
    <div class="autocomplete shadow">
        <label class="ac-label" for="search">
            Suche in Tags:
        </label>

        <div class="ac-input-wrap">
            <input class="ac-input"
                   type="text"
                   id="search"
                   placeholder="Hier tippen..."
                   v-model="searchTerm">
            <span class="ac-clear material-symbols-rounded" @click="searchTerm=''">clear</span>
        </div>

        <toggle-button class="ac-add" text2="" text1="anlegen"
                       icon2="" icon1="add" text-color="var(--text-dark)"
                       bg-color="var(--primary-color)" v-on:click="addNewTag(searchTerm)"
                       :state="true"></toggle-button>
        <div v-if="hasError" class="error-box shadow">Tag konnte nicht angelegt werden!</div>
        <ul v-if="searchTags.length && searchTerm !== ''" class="ac-results">
            <li class="ac-result-label">
                Zeigt {{ searchTags.length }} von {{ allTags.length }} Tags
            </li>
            <li class="ac-result-list-item" v-for="stag in searchTags" :key="stag.text"
                @click="selectTag(stag.tag_id)">
                {{ stag.text }}
            </li>
        </ul>
        <ul v-else class="ac-results">
            <li class="ac-result-label">
                Alle Tags
            </li>
            <li class="ac-result-list-item" v-for="stag in allTags" :key="stag.text"
                @click="selectTag(stag.tag_id)">
                {{ stag.text }}
            </li>
        </ul>
    </div>
</template>

<script>
    import APIConnector from "../scripts/APIConnector";
    import ToggleButton from "./ToggleButton";

    export default {
        name: "Autocomplete",
        components: {ToggleButton},
        props: {
            allTags: Array
        },
        data() {
            return {
                searchTerm: '',
                searchTags: [],
                selectedTag: {},
                apiConn: new APIConnector(),
                hasError: false
            }
        },
        watch: {
            searchTerm: function () {
                this.getFittingTags();
            }
        },
        methods: {
            getFittingTags() {
                if (this.searchTerm === '') {
                    this.searchTags = []
                }

                let matches = 0;

                this.searchTags = this.allTags.filter(tag => {
                    if (
                        tag.text.toLowerCase().includes(this.searchTerm.toLowerCase())
                        && matches < 10
                    ) {
                        matches++;
                        return tag
                    }
                });
            },
            selectTag(id) {
                let selectedTagObj = this.allTags.find(element => element.tag_id === id);
                // console.log(selectedTagObj);
                this.searchTerm = selectedTagObj.text;
                this.selectedTag = selectedTagObj;
                this.onTagSelected();
            },
            async addNewTag(newTag) {
                let found = this.allTags.find(element => element.text === newTag);
                if (found === undefined) {
                    let createdTag = await this.apiConn.addNewTag(newTag);
                    console.log('added new tag: ' + createdTag);
                    if (createdTag !== undefined) {
                        this.searchTerm = createdTag.text;
                        this.selectedTag = createdTag;
                        this.onTagSelected();
                    } else {
                        this.searchTerm = '';
                        this.hasError = true;
                    }
                }
            },
            onTagSelected() {
                this.$emit('tagselected', this.selectedTag);
            }
        }
    }
</script>

<style scoped>
    .autocomplete {
        width: min-content;
        min-width: 300px;
        display: grid;
        grid-template-areas: 'ac-label ac-label' 'ac-input-wrap ac-add' 'ac-results ac-results';
        grid-gap: 5px;
        grid-template-columns: min-content min-content;
        grid-template-rows: min-content min-content min-content;
        margin: 2em;
        padding: 1em;
        background-color: var(--background-dark);
        border-radius: 4px;
    }

    .ac-label {
        grid-area: ac-label;
        color: var(--text-dark);
        margin: 1em 0;
    }

    .ac-input-wrap {
        border-radius: 4px;
        background-color: var(--background);
        grid-area: ac-input-wrap;
        display: grid;
        grid-template-columns: min-content min-content;
        align-items: center;
    }

    .ac-input {
        border: none;
        outline: none;
        padding: 6px 6px;
        background-color: var(--background);
        color: var(--text-dark);
    }

    .ac-input:focus {
        border: 2px solid var(--secondary-color);
        border-radius: 4px;
    }

    .ac-clear {
        padding: 0.2em;
        cursor: pointer;
    }

    .ac-add {
        grid-area: ac-add;
    }

    .ac-results {
        list-style-type: none;
        grid-area: ac-results;
        margin: 0;
        padding: 0;
        color: var(--text-dark);
    }

    .ac-result-label {
        padding: 6px;
        margin: auto;
        font-size: 10pt;
    }

    .ac-result-list-item {
        padding: 6px;
        margin: auto;
        border-radius: 4px;
    }

    .ac-result-list-item:hover {
        background-color: var(--background);
    }
</style>
