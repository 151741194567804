<template>
    <div class="notify-box shadow" :class="showing ? 'showing' : ''" v-on:animationend="endAnimation">
        <p>&#128525;</p>
        <p>Vielen Dank!</p>
        <p>&#128151;</p>
    </div>
</template>

<script>
    export default {
        name: "NotifyBox",
        data(){
            return {
                showing: false
            }
        },
        methods: {
            start: function (){
                this.showing = true;
                console.log('notify start box: ' + this.showing);
            },
            endAnimation: function () {
                setTimeout(() => this.showing = false, 2000);
                console.log('notify end box: ' + this.showing);
            }
        }
    }
</script>

<style scoped>
    .notify-box {
        z-index: 10;
        position: absolute;
        bottom: 50%;
        left: 50%;
        background-color: var(--success-color);
        animation: 1.2s ease-in-out jello-horizontal;
        opacity: 0;
        display: none;
        transition: opacity 0.6s linear;
        padding: 1em;
        border-radius: 4px;
    }

    .notify-box p {
        color: var(--background);
        font-size: 16pt;
    }

    .showing {
        opacity: 0.7;
        display: grid;
        grid-template-columns: min-content auto min-content;
        grid-gap: 10px;
    }

    /* ----------------------------------------------
    * Generated by Animista on 2022-11-2 12:21:4
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info.
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation jello-horizontal
     * ----------------------------------------
     */
    @-webkit-keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

</style>
