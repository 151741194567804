<template>
    <div class="dropdown">
        <button class="dropbtn">
            <span>{{currentItem.name}}</span>
            <span class="material-symbols-rounded">keyboard_arrow_down</span>
        </button>
        <div class="dropdown-content">
            <span v-for="(item, index) in options" v-bind:key="index"
                  v-on:click="onSelected(item)">{{item.name}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dropdown-button",
        props: {
            options: Array
        },
        data() {
            return {
                currentItem: {name: '-'}
            }
        },
        created() {
            if (this.options.length > 0){
                this.currentItem = this.options[0];
            }
        },
        methods: {
            onSelected: function (item) {
                this.currentItem = item;
                this.$emit('selected', item);
                // console.log('dropdown selected: ' + item);
            }
        }
    }
</script>

<style scoped>
    /* Style The Dropdown Button */
    .dropbtn {
        background-color: var(--secondary-color);
        color: var(--background);
        border: 2px solid var(--secondary-color);
        border-radius: 3em;
        padding: 0.4em 1em;
        cursor: pointer;
        display: grid;
        grid-template-columns: min-content min-content;
    }

    .dropbtn span{
        margin: auto;
        text-align: center;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: var(--background-dark);
        min-width: 160px;
        z-index: 1;
        border-radius: 4px;
        max-height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* Links inside the dropdown */
    .dropdown-content span {
        color: var(--text-dark);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content span:hover {
        background-color: var(--primary-color)
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {
        background-color: var(--secondary-color);
    }
</style>
