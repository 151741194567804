<template>
    <div class="mini-clip" v-if="clipData">
        <div class="tags">
            <chip-button v-for="t in clipData.tags" :key="t.tag_id" :text="t.text" :active="true"
                         bg-color="var(--primary-color)" text-color-active="var(--text-dark)"></chip-button>
        </div>
        <div class="video-mini-container" :class="paused ? 'paused' : ''" v-on:click="togglePlay">
            <video class="video-mini" :ref="'v-' + (clipData.id || 0)" :width="size" :height="size"
                   v-on:ended="stopVideo"></video>
            <div class="overlay"></div>
            <span class="timestamp">{{clipData.createdAt || '-'}}</span>
        </div>
        <div class="save-buttons">
            <toggle-button v-if="useTextButtons" icon1="save" text1="lokal speichern"
                           :state="true" text-color="var(--text-dark)"
                           bg-color="var(--secondary-color)" v-on:click="saveClipLocal"></toggle-button>
            <notify-box ref="notifyBox"></notify-box>
            <toggle-button v-if="useTextButtons" icon1="cloud_upload"
                           :text1="clipData.sendToServer ? 'hochgeladen' : 'zum Server senden'"
                           :state="true" text-color="var(--text-dark)"
                           :bg-color="uploadColor" v-on:click="saveClipServer"></toggle-button>
            <toggle-button v-if="useTextButtons" icon1="delete" text1="Video löschen"
                           :state="true" text-color="var(--text-dark)"
                           bg-color="var(--error-color)" v-on:click="deleteClip"></toggle-button>
        </div>
    </div>
</template>

<script>
    import ChipButton from "./ChipButton";
    import ToggleButton from "./ToggleButton";
    import APIConnector from "../scripts/APIConnector";
    import NotifyBox from "./NotifyBox";

    export default {
        name: "gallery-clip",
        components: {NotifyBox, ToggleButton, ChipButton},
        props: {
            clipData: Object,
            useTextButtons: Boolean,
            dbHandler: Object
        },
        data() {
            return {
                video: {},
                paused: true,
                size: Number,
                unsubscribeStoreMutations: () => {
                },
                mode: 0,
                apiConn: new APIConnector(),
                uploadColor: 'var(--secondary-color)',
                localClipData: {}
            }
        },
        created() {
            this.mode = this.$store.state.trainMode;
            this.size = this.$store.state.imageSize;
            if (this.clipData.sendToServer) {
                this.uploadColor = 'var(--success-color)';
            }
            // watch store
            /*this.unsubscribeStoreMutations = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'updateTrainMode') {
                    this.mode = state.trainMode;
                }
            });*/
        },
        mounted() {
            this.localClipData = this.clipData;
            let videoRef = this.$refs['v-' + this.clipData.id];
            if (this.clipData !== undefined && videoRef !== undefined) {
                this.video = videoRef;
                this.paused = this.video.paused;
                // console.log('clip from video: ' + this.textButtons);
                // console.log(this.clipData.video);
                if (this.clipData.video !== undefined) { // empty {} check && Object.keys(this.clipData.video).length !== 0) {
                    this.video.src = URL.createObjectURL(this.clipData.video);
                    console.log('Set video src for ' + this.clipData.video);
                    console.log(this.video.src);
                } else {
                    console.log(this.clipData);
                    console.log('Error: could not set video src for ' + this.clipData.id);
                }
            } else {
                console.log('Error: could not set video src for ' + this.clipData.id);
            }
        },
        methods: {
            togglePlay: function () {
                this.paused = this.video.paused || this.video.ended;
                if (this.paused) {
                    this.video.play();
                    this.paused = false;
                } else {
                    this.video.pause();
                    this.paused = true;
                }
                // console.log('toggle ' + this.clipData.id + ' paused: ' + this.paused);
            },
            stopVideo: function () {
                if (!this.video.paused || !this.video.ended) {
                    this.video.pause();
                }
                this.paused = true;
                // console.log('video ended: ' + this.paused);
            },
            saveClipLocal: function () {
                if (this.clipData.video !== undefined) {
                    let filename = 'session_' + this.clipData.label + '_' + this.clipData.createdAt;
                    const blob = this.clipData.video;
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    URL.revokeObjectURL(link.href);
                } else {
                    console.log('video not found');
                }

            },
            saveClipServer: async function () {
                // todo: check if tag ausgewählt
                if (this.clipData.video !== undefined) {
                    if (this.clipData.sendToServer){
                        console.log('test');
                        if (!window.confirm("Das Video wurde bereits hochgeladen! Trotzdem hochladen?")) {
                            console.log('test');
                            return;
                        }
                    }
                    let success = await this.apiConn.uploadVideo(this.clipData);
                    if (success) {
                        this.uploadColor = 'var(--success-color)';
                        this.localClipData.sendToServer = true;
                        this.dbHandler.updateClipData(this.localClipData);
                        this.$refs.notifyBox.start();
                    } else {
                        this.uploadColor = 'var(--error-color)';
                    }
                } else {
                    this.uploadColor = 'var(--error-color)';
                    console.log('video not found');
                }
            },
            deleteClip: function () {
                this.dbHandler.deleteClip(this.clipData);
            }
        },
        unmounted() {
            this.unsubscribeStoreMutations();
        }
    }
</script>

<style scoped>
    .mini-clip {
        padding: 0.4em;
        display: grid;
        justify-items: center;
    }

    .video-mini-container {
        border-radius: 4px;
        padding: 0.2em;
        position: relative;
    }

    .video-mini {
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .paused .overlay {
        opacity: 0.4;
    }

    .overlay {
        position: absolute;
        top: 0.2em;
        left: 0.2em;
        opacity: 0;
        width: 98%;
        height: 96%;
        transition: .5s ease;
        background-color: var(--secondary-color);
        background-image: url("../../public/img/assets/play.svg");
        background-size: 64px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .timestamp {
        position: absolute;
        bottom: 0.6em;
        left: 0.3em;
        font-size: 10pt;
        background-color: rgba(var(--primary-rgb), 0.6);
        color: var(--text-dark);
        padding: 0 0.2em;
    }

    .save-buttons {
        display: grid;
        grid-template-columns: min-content min-content;
    }

    .tags {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: min-content;
    }
</style>
