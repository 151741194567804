<template>
    <div class="results-container main-content">
        <results></results>
        <!--<mini-clip class="current" :clip-data="currentClip" :text-buttons="true"></mini-clip>-->
        <div class="actions">
            <toggle-button text1="neue Aufnahme" text-color="var(--text-dark)"
                           bg-color="var(--primary-color)" v-on:click="newRecording"
                           :state="true"></toggle-button>
            <toggle-button text1="Clip-Galerie" text-color="var(--text-dark)"
                           bg-color="var(--secondary-color)" v-on:click="gotToGallery"
                           :state="true"></toggle-button>
        </div>
    </div>
</template>

<script>

    import Results from "../Results";
    import ToggleButton from "../ToggleButton";
    // import MiniClip from "../MiniClip";
    import DatabaseHandler from "../../scripts/DatabaseHandler";

    export default {
        name: "ResultsPage",
        components: {ToggleButton, Results},
        data() {
            return {
                unsubscribeStore: () => {
                },
                recordingRunning: false,
                currentClip: {},
                dbHandler: {},
            }
        },
        async created() {
            this.dbHandler = new DatabaseHandler('training_data', this.$store);
            this.initVideo();
            // watch store
            this.unsubscribeStore = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'toggleRecording') {
                    this.recordingRunning = state.recordingRunning;
                }/* else if (mutation.type === 'updateCurrentVideo') {
                    if (this.$store.state.currentVideoTag !== {}) {
                        this.onnxRunner.video = this.$store.state.currentVideoTag;
                    }
                }*/
            })
        },
        methods: {
            async initVideo() {
                let clip = await this.dbHandler.getCurrentClip();
                if (clip !== undefined) {
                    this.currentClip = clip;
                    console.log('set current clip: ' + clip.id);
                } else {
                    console.log('no current clip found');
                }
            },
            newRecording: function () {
                this.$router.push('/record');
            },
            gotToGallery: function () {
                this.$router.push('/gallery');
            }
        },
        unmounted() {
            this.unsubscribeStore();
        }
    }
</script>

<style scoped>
    .results-container {
        padding: 2em;
        width: calc(100% - 2em);
        height: 100%;
        display: grid;
        grid-template-areas: 'results' 'actions';
        grid-template-rows: auto auto;
        grid-gap: 20px;
        align-items: start;
    }

    .actions {
        grid-area: actions;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;

    }
</style>
