<template>
    <div class="main-content styled-scrollbars">
        <div class="editing-actions">
            <toggle-button class="gallery-actions" text1="neue Aufnahme" icon1="radio_button_checked"
                           text-color="var(--text-dark)"
                           bg-color="var(--primary-color)" v-on:click="newRecording"
                           :state="true"></toggle-button>
            <toggle-button class="gallery-actions" text1="zur Galerie" icon1="video_library"
                           text-color="var(--text-dark)"
                           bg-color="var(--secondary-color)" v-on:click="goToGallery"
                           :state="true"></toggle-button>
        </div>
        <div class="edit-info">
            <p>
                Hier kannst Du weitere Tags hinzufügen, die passen.
                [In der nächsten Version kann das Video auch zugeschnitten werden.]
            </p>
        </div>
        <autocomplete class="edit-tag-ac" :all-tags="allTags" @tagselected="addSelectedTag"></autocomplete>
        <div class="edit-clip">
            <div class="tags">
                <chip-button v-for="(t, i) in clipData.tags" :key="t.tag_id" :text="t.text" :active="true"
                             bg-color="var(--secondary-color)" text-color-active="var(--text-dark)"
                             v-on:click="removeTag(i)" :show-delete="true"></chip-button>
            </div>
            <div class="video-mini-container" :class="paused ? 'paused' : ''" v-on:click="togglePlay">
                <video class="video-mini" :ref="'v-' + (clipData.id || 0)" :width="Math.floor(videoSize*1.8)"
                       :height="videoSize" v-on:ended="stopVideo"></video>
                <div class="overlay"></div>
                <span class="timestamp">{{clipData.createdAt || '-'}}</span>
            </div>
            <div class="save-buttons">
                <toggle-button icon1="save" text1="lokal speichern"
                               :state="true" text-color="var(--text-dark)"
                               bg-color="var(--secondary-color)" v-on:click="saveClipLocal"></toggle-button>
                <notify-box ref="notifyBox"></notify-box>
                <toggle-button icon1="cloud_upload" :text1="clipData.sendToServer ? 'hochgeladen' : 'zum Server senden'"
                               :state="true" text-color="var(--text-dark)"
                               :bg-color="uploadColor" v-on:click="saveClipServer"></toggle-button>
                <toggle-button icon1="delete" text1="Video löschen"
                               :state="true" text-color="var(--text-dark)"
                               bg-color="var(--error-color)" v-on:click="deleteClip"></toggle-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ToggleButton from "../ToggleButton";
    import DatabaseHandler from "../../scripts/DatabaseHandler";
    import Autocomplete from "../Autocomplete";
    import APIConnector from "../../scripts/APIConnector";
    import ChipButton from "../ChipButton";
    import NotifyBox from "../NotifyBox";

    export default {
        name: "ClipEditingPage",
        components: {ToggleButton, Autocomplete, ChipButton, NotifyBox},
        data() {
            return {
                videoSize: 400,
                dbHandler: {},
                video: {},
                clipData: {},
                allTags: [],
                apiConn: new APIConnector(),
                uploadColor: 'var(--secondary-color)',
                paused: true
            }
        },
        async created() {
            this.dbHandler = new DatabaseHandler('training_data', this.$store);
            this.clipData = await this.dbHandler.getCurrentClip();
            if (this.clipData.sendToServer) {
                this.uploadColor = 'var(--success-color)';
            }
            this.getAllTags();
        },
        mounted() {
            let videoRef = this.$refs['v-' + this.clipData.id];
            if (this.clipData !== undefined && videoRef !== undefined) {
                this.video = videoRef;
                this.paused = this.video.paused;
                // console.log('clip from video: ' + this.textButtons);
                // console.log(this.clipData.video);
                if (this.clipData.video !== undefined) { // empty {} check && Object.keys(this.clipData.video).length !== 0) {
                    this.video.src = URL.createObjectURL(this.clipData.video);
                    console.log('Set video src for ' + this.clipData.video);
                    console.log(this.video.src);
                } else {
                    console.log(this.clipData);
                    console.log('Error: could not set video src for ' + this.clipData.id);
                }
            } else {
                console.log('Error: could not set video src for ' + this.clipData.id);
            }
        },
        methods: {
            async getAllTags() {
                this.apiConn.getAllTags().then(tags => {
                    // console.log('new tags from api:');
                    this.allTags = tags;
                });
            },
            addSelectedTag(tag) {
                this.clipData.tags.push(tag);
                this.dbHandler.updateClipData(this.clipData);
                console.log(this.clipData);
            },
            removeTag(index) {
                if (index > -1) { // only splice array when item is found
                    this.clipData.tags.splice(index, 1); // 2nd parameter means remove one item only
                    this.dbHandler.updateClipData(this.clipData);
                    console.log(this.clipData);
                }
            },
            togglePlay: function () {
                this.paused = this.video.paused || this.video.ended;
                if (this.paused) {
                    this.video.play();
                    this.paused = false;
                } else {
                    this.video.pause();
                    this.paused = true;
                }
                // console.log('toggle ' + this.clipData.id + ' paused: ' + this.paused);
            },
            stopVideo: function () {
                if (!this.video.paused || !this.video.ended) {
                    this.video.pause();
                }
                this.paused = true;
                // console.log('video ended: ' + this.paused);
            },
            saveClipLocal: function () {
                if (this.clipData.video !== undefined) {
                    let filename = 'session_' + this.clipData.label + '_' + this.clipData.createdAt;
                    const blob = this.clipData.video;
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    URL.revokeObjectURL(link.href);
                } else {
                    console.log('video not found');
                }

            },
            saveClipServer: async function () {
                // todo: check if tag ausgewählt
                if (this.clipData.video !== undefined) {
                    if (this.clipData.sendToServer){
                        this.$refs.notifyBox.start();
                        return;
                    }
                    let success = await this.apiConn.uploadVideo(this.clipData);
                    if (success) {
                        this.uploadColor = 'var(--success-color)';
                        this.clipData.sendToServer = true;
                        this.dbHandler.updateClipData(this.clipData);
                        this.showNotification = true;
                    } else {
                        this.uploadColor = 'var(--error-color)';
                    }
                } else {
                    this.uploadColor = 'var(--error-color)';
                    console.log('video not found');
                }
            },
            deleteClip: function () {
                this.dbHandler.deleteClip(this.clipData);
            },
            newRecording: function () {
                this.$router.push('/record');
            },
            goToGallery: function () {
                this.$router.push('/gallery');
            }
        }
    }
</script>

<style scoped>
    .main-content {
        display: grid;
        width: 100%;
        grid-template-areas: 'editing-actions editing-actions' 'edit-info edit-info' 'edit-tag-ac edit-clip';
        grid-template-rows: min-content min-content auto;
        grid-template-columns: min-content auto;
        justify-items: center;
        overflow-x: hidden;
    }

    .edit-info {
        grid-area: edit-info;
        margin: 1em;
        padding: 1em;
    }

    .edit-tag-ac {
        grid-area: edit-tag-ac;
    }

    .edit-clip {
        grid-area: edit-clip;
    }

    .editing-actions {
        grid-area: editing-actions;
        margin: 1em;
        display: grid;
        grid-auto-columns: min-content;
        grid-auto-flow: column;
        grid-gap: 2em;
    }

    .video-mini-container {
        border-radius: 4px;
        padding: 0.2em;
        position: relative;
    }

    .video-mini {
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .paused .overlay {
        opacity: 0.4;
    }

    .overlay {
        position: absolute;
        top: 0.2em;
        left: 0.2em;
        opacity: 0;
        width: 99%;
        height: 97%;
        transition: .5s ease;
        background-color: var(--secondary-color);
        background-image: url("../../../public/img/assets/play.svg");
        background-size: 64px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .timestamp {
        position: absolute;
        bottom: 0.6em;
        left: 0.3em;
        font-size: 10pt;
        background-color: rgba(var(--primary-rgb), 0.6);
        color: var(--text-dark);
        padding: 0 0.2em;
    }

    .save-buttons {
        display: grid;
        grid-template-columns: min-content min-content min-content;
    }

    .tags {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: min-content;
    }

</style>
