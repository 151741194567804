import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { createStore } from 'vuex';
import * as VueRouter from "vue-router";
import createPersistedState from "vuex-persistedstate";

import IntroSettingsPage from "./components/pages/IntroSettingsPage";
import RecordPage from "./components/pages/RecordPage";
import ResultsPage from "./components/pages/ResultsPage";
import GalleryPage from "./components/pages/GalleryPage";
import ClipEditingPage from "./components/pages/ClipEditingPage";

// Create a new store instance.
const store = createStore({
    plugins: [createPersistedState()], // save state on refresh
    state () {
        return {
            // clips: [],
            queueSize: 5,
            recordingTime: 5000,
            imageSize: 224,
            sessionStarted: false,
            recordingRunning: false,
            savingRunning: false,
            // currentVideoTag: {},
            // predictions: [],
            trainMode: 0,
            runnerMode: 0,
            taggingMode: 0,
        }
    },
    mutations: {
       /* addToQueue (state, clip) {
            if (state.clips.length >= state.queueSize) {
                state.clips.shift();
            }
            state.clips.push(clip);
        },*/
        toggleSession(state, start){
            state.sessionStarted = start;
        },
        toggleRecording(state, start){
            state.recordingRunning = start;
        },
        toggleSaving(state, start){
            state.savingRunning = start;
        },
     /*   updateCurrentVideo(state, stream){
            state.currentVideoTag = stream;
        },
        updatePredictions(state, preds){
            state.predictions = preds;
        },*/
        updateTrainMode(state, mode){
            state.trainMode = mode;
        },
        updateRunnerMode(state, mode){
            state.runnerMode = mode;
        },
        updateTaggingMode(state, mode){
            state.taggingMode = mode;
        }
    }
});

const routes = [
    { path: '/', component: IntroSettingsPage },
    { path: '/record', component: RecordPage },
    { path: '/editing', component: ClipEditingPage },
    { path: '/results', component: ResultsPage },
    { path: '/gallery', component: GalleryPage },
];

const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHashHistory(),
    routes, // short for `routes: routes`
});

const app = createApp(App);

app.use(store);
app.use(router);

app.mount('#app');
