/**
 * A Mapping of the output classes for Kinetics600.
 */
export default class Kinetics600ClassList {

    static classObj = {
        '0': ['n01440764', 'tench'],
        '1': ['n01443537', 'goldfish'],
        '2': ['n01484850', 'great_white_shark'],
        '3': ['n01491361', 'tiger_shark'],
        '4': ['n01494475', 'hammerhead'],
        '5': ['n01496331', 'electric_ray'],
        '6': ['n01498041', 'stingray'],
        '7': ['n01514668', 'cock'],
        '8': ['n01514859', 'hen'],
        '9': ['n01518878', 'ostrich'],
        '10': ['n01530575', 'brambling'],
        '11': ['n01531178', 'goldfinch'],
        '12': ['n01532829', 'house_finch'],
        '13': ['n01534433', 'junco'],
        '14': ['n01537544', 'indigo_bunting'],
        '15': ['n01558993', 'robin'],
        '16': ['n01560419', 'bulbul'],
        '17': ['n01580077', 'jay'],
        '18': ['n01582220', 'magpie'],
        '19': ['n01592084', 'chickadee'],
        '20': ['n01601694', 'water_ouzel'],
        '21': ['n01608432', 'kite'],
        '22': ['n01614925', 'bald_eagle'],
        '23': ['n01616318', 'vulture'],
        '24': ['n01622779', 'great_grey_owl'],
        '25': ['n01629819', 'European_fire_salamander'],
        '26': ['n01630670', 'common_newt'],
        '27': ['n01631663', 'eft'],
        '28': ['n01632458', 'spotted_salamander'],
        '29': ['n01632777', 'axolotl'],
        '30': ['n01641577', 'bullfrog'],
        '31': ['n01644373', 'tree_frog'],
        '32': ['n01644900', 'tailed_frog'],
        '33': ['n01664065', 'loggerhead'],
        '34': ['n01665541', 'leatherback_turtle'],
        '35': ['n01667114', 'mud_turtle'],
        '36': ['n01667778', 'terrapin'],
        '37': ['n01669191', 'box_turtle'],
        '38': ['n01675722', 'banded_gecko'],
        '39': ['n01677366', 'common_iguana'],
        '40': ['n01682714', 'American_chameleon'],
        '41': ['n01685808', 'whiptail'],
        '42': ['n01687978', 'agama'],
        '43': ['n01688243', 'frilled_lizard'],
        '44': ['n01689811', 'alligator_lizard'],
        '45': ['n01692333', 'Gila_monster'],
        '46': ['n01693334', 'green_lizard'],
        '47': ['n01694178', 'African_chameleon'],
        '48': ['n01695060', 'Komodo_dragon'],
        '49': ['n01697457', 'African_crocodile'],
        '50': ['n01698640', 'American_alligator'],
        '51': ['n01704323', 'triceratops'],
        '52': ['n01728572', 'thunder_snake'],
        '53': ['n01728920', 'ringneck_snake'],
        '54': ['n01729322', 'hognose_snake'],
        '55': ['n01729977', 'green_snake'],
        '56': ['n01734418', 'king_snake'],
        '57': ['n01735189', 'garter_snake'],
        '58': ['n01737021', 'water_snake'],
        '59': ['n01739381', 'vine_snake'],
        '60': ['n01740131', 'night_snake'],
        '61': ['n01742172', 'boa_constrictor'],
        '62': ['n01744401', 'rock_python'],
        '63': ['n01748264', 'Indian_cobra'],
        '64': ['n01749939', 'green_mamba'],
        '65': ['n01751748', 'sea_snake'],
        '66': ['n01753488', 'horned_viper'],
        '67': ['n01755581', 'diamondback'],
        '68': ['n01756291', 'sidewinder'],
        '69': ['n01768244', 'trilobite'],
        '70': ['n01770081', 'harvestman'],
        '71': ['n01770393', 'scorpion'],
        '72': ['n01773157', 'black_and_gold_garden_spider'],
        '73': ['n01773549', 'barn_spider'],
        '74': ['n01773797', 'garden_spider'],
        '75': ['n01774384', 'black_widow'],
        '76': ['n01774750', 'tarantula'],
        '77': ['n01775062', 'wolf_spider'],
        '78': ['n01776313', 'tick'],
        '79': ['n01784675', 'centipede'],
        '80': ['n01795545', 'black_grouse'],
        '81': ['n01796340', 'ptarmigan'],
        '82': ['n01797886', 'ruffed_grouse'],
        '83': ['n01798484', 'prairie_chicken'],
        '84': ['n01806143', 'peacock'],
        '85': ['n01806567', 'quail'],
        '86': ['n01807496', 'partridge'],
        '87': ['n01817953', 'African_grey'],
        '88': ['n01818515', 'macaw'],
        '89': ['n01819313', 'sulphur-crested_cockatoo'],
        '90': ['n01820546', 'lorikeet'],
        '91': ['n01824575', 'coucal'],
        '92': ['n01828970', 'bee_eater'],
        '93': ['n01829413', 'hornbill'],
        '94': ['n01833805', 'hummingbird'],
        '95': ['n01843065', 'jacamar'],
        '96': ['n01843383', 'toucan'],
        '97': ['n01847000', 'drake'],
        '98': ['n01855032', 'red-breasted_merganser'],
        '99': ['n01855672', 'goose'],
        '100': ['n01860187', 'black_swan'],
        '101': ['n01871265', 'tusker'],
        '102': ['n01872401', 'echidna'],
        '103': ['n01873310', 'platypus'],
        '104': ['n01877812', 'wallaby'],
        '105': ['n01882714', 'koala'],
        '106': ['n01883070', 'wombat'],
        '107': ['n01910747', 'jellyfish'],
        '108': ['n01914609', 'sea_anemone'],
        '109': ['n01917289', 'brain_coral'],
        '110': ['n01924916', 'flatworm'],
        '111': ['n01930112', 'nematode'],
        '112': ['n01943899', 'conch'],
        '113': ['n01944390', 'snail'],
        '114': ['n01945685', 'slug'],
        '115': ['n01950731', 'sea_slug'],
        '116': ['n01955084', 'chiton'],
        '117': ['n01968897', 'chambered_nautilus'],
        '118': ['n01978287', 'Dungeness_crab'],
        '119': ['n01978455', 'rock_crab'],
        '120': ['n01980166', 'fiddler_crab'],
        '121': ['n01981276', 'king_crab'],
        '122': ['n01983481', 'American_lobster'],
        '123': ['n01984695', 'spiny_lobster'],
        '124': ['n01985128', 'crayfish'],
        '125': ['n01986214', 'hermit_crab'],
        '126': ['n01990800', 'isopod'],
        '127': ['n02002556', 'white_stork'],
        '128': ['n02002724', 'black_stork'],
        '129': ['n02006656', 'spoonbill'],
        '130': ['n02007558', 'flamingo'],
        '131': ['n02009229', 'little_blue_heron'],
        '132': ['n02009912', 'American_egret'],
        '133': ['n02011460', 'bittern'],
        '134': ['n02012849', 'crane'],
        '135': ['n02013706', 'limpkin'],
        '136': ['n02017213', 'European_gallinule'],
        '137': ['n02018207', 'American_coot'],
        '138': ['n02018795', 'bustard'],
        '139': ['n02025239', 'ruddy_turnstone'],
        '140': ['n02027492', 'red-backed_sandpiper'],
        '141': ['n02028035', 'redshank'],
        '142': ['n02033041', 'dowitcher'],
        '143': ['n02037110', 'oystercatcher'],
        '144': ['n02051845', 'pelican'],
        '145': ['n02056570', 'king_penguin'],
        '146': ['n02058221', 'albatross'],
        '147': ['n02066245', 'grey_whale'],
        '148': ['n02071294', 'killer_whale'],
        '149': ['n02074367', 'dugong'],
        '150': ['n02077923', 'sea_lion'],
        '151': ['n02085620', 'Chihuahua'],
        '152': ['n02085782', 'Japanese_spaniel'],
        '153': ['n02085936', 'Maltese_dog'],
        '154': ['n02086079', 'Pekinese'],
        '155': ['n02086240', 'Shih-Tzu'],
        '156': ['n02086646', 'Blenheim_spaniel'],
        '157': ['n02086910', 'papillon'],
        '158': ['n02087046', 'toy_terrier'],
        '159': ['n02087394', 'Rhodesian_ridgeback'],
        '160': ['n02088094', 'Afghan_hound'],
        '161': ['n02088238', 'basset'],
        '162': ['n02088364', 'beagle'],
        '163': ['n02088466', 'bloodhound'],
        '164': ['n02088632', 'bluetick'],
        '165': ['n02089078', 'black-and-tan_coonhound'],
        '166': ['n02089867', 'Walker_hound'],
        '167': ['n02089973', 'English_foxhound'],
        '168': ['n02090379', 'redbone'],
        '169': ['n02090622', 'borzoi'],
        '170': ['n02090721', 'Irish_wolfhound'],
        '171': ['n02091032', 'Italian_greyhound'],
        '172': ['n02091134', 'whippet'],
        '173': ['n02091244', 'Ibizan_hound'],
        '174': ['n02091467', 'Norwegian_elkhound'],
        '175': ['n02091635', 'otterhound'],
        '176': ['n02091831', 'Saluki'],
        '177': ['n02092002', 'Scottish_deerhound'],
        '178': ['n02092339', 'Weimaraner'],
        '179': ['n02093256', 'Staffordshire_bullterrier'],
        '180': ['n02093428', 'American_Staffordshire_terrier'],
        '181': ['n02093647', 'Bedlington_terrier'],
        '182': ['n02093754', 'Border_terrier'],
        '183': ['n02093859', 'Kerry_blue_terrier'],
        '184': ['n02093991', 'Irish_terrier'],
        '185': ['n02094114', 'Norfolk_terrier'],
        '186': ['n02094258', 'Norwich_terrier'],
        '187': ['n02094433', 'Yorkshire_terrier'],
        '188': ['n02095314', 'wire-haired_fox_terrier'],
        '189': ['n02095570', 'Lakeland_terrier'],
        '190': ['n02095889', 'Sealyham_terrier'],
        '191': ['n02096051', 'Airedale'],
        '192': ['n02096177', 'cairn'],
        '193': ['n02096294', 'Australian_terrier'],
        '194': ['n02096437', 'Dandie_Dinmont'],
        '195': ['n02096585', 'Boston_bull'],
        '196': ['n02097047', 'miniature_schnauzer'],
        '197': ['n02097130', 'giant_schnauzer'],
        '198': ['n02097209', 'standard_schnauzer'],
        '199': ['n02097298', 'Scotch_terrier'],
        '200': ['n02097474', 'Tibetan_terrier'],
        '201': ['n02097658', 'silky_terrier'],
        '202': ['n02098105', 'soft-coated_wheaten_terrier'],
        '203': ['n02098286', 'West_Highland_white_terrier'],
        '204': ['n02098413', 'Lhasa'],
        '205': ['n02099267', 'flat-coated_retriever'],
        '206': ['n02099429', 'curly-coated_retriever'],
        '207': ['n02099601', 'golden_retriever'],
        '208': ['n02099712', 'Labrador_retriever'],
        '209': ['n02099849', 'Chesapeake_Bay_retriever'],
        '210': ['n02100236', 'German_short-haired_pointer'],
        '211': ['n02100583', 'vizsla'],
        '212': ['n02100735', 'English_setter'],
        '213': ['n02100877', 'Irish_setter'],
        '214': ['n02101006', 'Gordon_setter'],
        '215': ['n02101388', 'Brittany_spaniel'],
        '216': ['n02101556', 'clumber'],
        '217': ['n02102040', 'English_springer'],
        '218': ['n02102177', 'Welsh_springer_spaniel'],
        '219': ['n02102318', 'cocker_spaniel'],
        '220': ['n02102480', 'Sussex_spaniel'],
        '221': ['n02102973', 'Irish_water_spaniel'],
        '222': ['n02104029', 'kuvasz'],
        '223': ['n02104365', 'schipperke'],
        '224': ['n02105056', 'groenendael'],
        '225': ['n02105162', 'malinois'],
        '226': ['n02105251', 'briard'],
        '227': ['n02105412', 'kelpie'],
        '228': ['n02105505', 'komondor'],
        '229': ['n02105641', 'Old_English_sheepdog'],
        '230': ['n02105855', 'Shetland_sheepdog'],
        '231': ['n02106030', 'collie'],
        '232': ['n02106166', 'Border_collie'],
        '233': ['n02106382', 'Bouvier_des_Flandres'],
        '234': ['n02106550', 'Rottweiler'],
        '235': ['n02106662', 'German_shepherd'],
        '236': ['n02107142', 'Doberman'],
        '237': ['n02107312', 'miniature_pinscher'],
        '238': ['n02107574', 'Greater_Swiss_Mountain_dog'],
        '239': ['n02107683', 'Bernese_mountain_dog'],
        '240': ['n02107908', 'Appenzeller'],
        '241': ['n02108000', 'EntleBucher'],
        '242': ['n02108089', 'boxer'],
        '243': ['n02108422', 'bull_mastiff'],
        '244': ['n02108551', 'Tibetan_mastiff'],
        '245': ['n02108915', 'French_bulldog'],
        '246': ['n02109047', 'Great_Dane'],
        '247': ['n02109525', 'Saint_Bernard'],
        '248': ['n02109961', 'Eskimo_dog'],
        '249': ['n02110063', 'malamute'],
        '250': ['n02110185', 'Siberian_husky'],
        '251': ['n02110341', 'dalmatian'],
        '252': ['n02110627', 'affenpinscher'],
        '253': ['n02110806', 'basenji'],
        '254': ['n02110958', 'pug'],
        '255': ['n02111129', 'Leonberg'],
        '256': ['n02111277', 'Newfoundland'],
        '257': ['n02111500', 'Great_Pyrenees'],
        '258': ['n02111889', 'Samoyed'],
        '259': ['n02112018', 'Pomeranian'],
        '260': ['n02112137', 'chow'],
        '261': ['n02112350', 'keeshond'],
        '262': ['n02112706', 'Brabancon_griffon'],
        '263': ['n02113023', 'Pembroke'],
        '264': ['n02113186', 'Cardigan'],
        '265': ['n02113624', 'toy_poodle'],
        '266': ['n02113712', 'miniature_poodle'],
        '267': ['n02113799', 'standard_poodle'],
        '268': ['n02113978', 'Mexican_hairless'],
        '269': ['n02114367', 'timber_wolf'],
        '270': ['n02114548', 'white_wolf'],
        '271': ['n02114712', 'red_wolf'],
        '272': ['n02114855', 'coyote'],
        '273': ['n02115641', 'dingo'],
        '274': ['n02115913', 'dhole'],
        '275': ['n02116738', 'African_hunting_dog'],
        '276': ['n02117135', 'hyena'],
        '277': ['n02119022', 'red_fox'],
        '278': ['n02119789', 'kit_fox'],
        '279': ['n02120079', 'Arctic_fox'],
        '280': ['n02120505', 'grey_fox'],
        '281': ['n02123045', 'tabby'],
        '282': ['n02123159', 'tiger_cat'],
        '283': ['n02123394', 'Persian_cat'],
        '284': ['n02123597', 'Siamese_cat'],
        '285': ['n02124075', 'Egyptian_cat'],
        '286': ['n02125311', 'cougar'],
        '287': ['n02127052', 'lynx'],
        '288': ['n02128385', 'leopard'],
        '289': ['n02128757', 'snow_leopard'],
        '290': ['n02128925', 'jaguar'],
        '291': ['n02129165', 'lion'],
        '292': ['n02129604', 'tiger'],
        '293': ['n02130308', 'cheetah'],
        '294': ['n02132136', 'brown_bear'],
        '295': ['n02133161', 'American_black_bear'],
        '296': ['n02134084', 'ice_bear'],
        '297': ['n02134418', 'sloth_bear'],
        '298': ['n02137549', 'mongoose'],
        '299': ['n02138441', 'meerkat'],
        '300': ['n02165105', 'tiger_beetle'],
        '301': ['n02165456', 'ladybug'],
        '302': ['n02167151', 'ground_beetle'],
        '303': ['n02168699', 'long-horned_beetle'],
        '304': ['n02169497', 'leaf_beetle'],
        '305': ['n02172182', 'dung_beetle'],
        '306': ['n02174001', 'rhinoceros_beetle'],
        '307': ['n02177972', 'weevil'],
        '308': ['n02190166', 'fly'],
        '309': ['n02206856', 'bee'],
        '310': ['n02219486', 'ant'],
        '311': ['n02226429', 'grasshopper'],
        '312': ['n02229544', 'cricket'],
        '313': ['n02231487', 'walking_stick'],
        '314': ['n02233338', 'cockroach'],
        '315': ['n02236044', 'mantis'],
        '316': ['n02256656', 'cicada'],
        '317': ['n02259212', 'leafhopper'],
        '318': ['n02264363', 'lacewing'],
        '319': ['n02268443', 'dragonfly'],
        '320': ['n02268853', 'damselfly'],
        '321': ['n02276258', 'admiral'],
        '322': ['n02277742', 'ringlet'],
        '323': ['n02279972', 'monarch'],
        '324': ['n02280649', 'cabbage_butterfly'],
        '325': ['n02281406', 'sulphur_butterfly'],
        '326': ['n02281787', 'lycaenid'],
        '327': ['n02317335', 'starfish'],
        '328': ['n02319095', 'sea_urchin'],
        '329': ['n02321529', 'sea_cucumber'],
        '330': ['n02325366', 'wood_rabbit'],
        '331': ['n02326432', 'hare'],
        '332': ['n02328150', 'Angora'],
        '333': ['n02342885', 'hamster'],
        '334': ['n02346627', 'porcupine'],
        '335': ['n02356798', 'fox_squirrel'],
        '336': ['n02361337', 'marmot'],
        '337': ['n02363005', 'beaver'],
        '338': ['n02364673', 'guinea_pig'],
        '339': ['n02389026', 'sorrel'],
        '340': ['n02391049', 'zebra'],
        '341': ['n02395406', 'hog'],
        '342': ['n02396427', 'wild_boar'],
        '343': ['n02397096', 'warthog'],
        '344': ['n02398521', 'hippopotamus'],
        '345': ['n02403003', 'ox'],
        '346': ['n02408429', 'water_buffalo'],
        '347': ['n02410509', 'bison'],
        '348': ['n02412080', 'ram'],
        '349': ['n02415577', 'bighorn'],
        '350': ['n02417914', 'ibex'],
        '351': ['n02422106', 'hartebeest'],
        '352': ['n02422699', 'impala'],
        '353': ['n02423022', 'gazelle'],
        '354': ['n02437312', 'Arabian_camel'],
        '355': ['n02437616', 'llama'],
        '356': ['n02441942', 'weasel'],
        '357': ['n02442845', 'mink'],
        '358': ['n02443114', 'polecat'],
        '359': ['n02443484', 'black-footed_ferret'],
        '360': ['n02444819', 'otter'],
        '361': ['n02445715', 'skunk'],
        '362': ['n02447366', 'badger'],
        '363': ['n02454379', 'armadillo'],
        '364': ['n02457408', 'three-toed_sloth'],
        '365': ['n02480495', 'orangutan'],
        '366': ['n02480855', 'gorilla'],
        '367': ['n02481823', 'chimpanzee'],
        '368': ['n02483362', 'gibbon'],
        '369': ['n02483708', 'siamang'],
        '370': ['n02484975', 'guenon'],
        '371': ['n02486261', 'patas'],
        '372': ['n02486410', 'baboon'],
        '373': ['n02487347', 'macaque'],
        '374': ['n02488291', 'langur'],
        '375': ['n02488702', 'colobus'],
        '376': ['n02489166', 'proboscis_monkey'],
        '377': ['n02490219', 'marmoset'],
        '378': ['n02492035', 'capuchin'],
        '379': ['n02492660', 'howler_monkey'],
        '380': ['n02493509', 'titi'],
        '381': ['n02493793', 'spider_monkey'],
        '382': ['n02494079', 'squirrel_monkey'],
        '383': ['n02497673', 'Madagascar_cat'],
        '384': ['n02500267', 'indri'],
        '385': ['n02504013', 'Indian_elephant'],
        '386': ['n02504458', 'African_elephant'],
        '387': ['n02509815', 'lesser_panda'],
        '388': ['n02510455', 'giant_panda'],
        '389': ['n02514041', 'barracouta'],
        '390': ['n02526121', 'eel'],
        '391': ['n02536864', 'coho'],
        '392': ['n02606052', 'rock_beauty'],
        '393': ['n02607072', 'anemone_fish'],
        '394': ['n02640242', 'sturgeon'],
        '395': ['n02641379', 'gar'],
        '396': ['n02643566', 'lionfish'],
        '397': ['n02655020', 'puffer'],
        '398': ['n02666196', 'abacus'],
        '399': ['n02667093', 'abaya'],
        '400': ['n02669723', 'academic_gown'],
        '401': ['n02672831', 'accordion'],
        '402': ['n02676566', 'acoustic_guitar'],
        '403': ['n02687172', 'aircraft_carrier'],
        '404': ['n02690373', 'airliner'],
        '405': ['n02692877', 'airship'],
        '406': ['n02699494', 'altar'],
        '407': ['n02701002', 'ambulance'],
        '408': ['n02704792', 'amphibian'],
        '409': ['n02708093', 'analog_clock'],
        '410': ['n02727426', 'apiary'],
        '411': ['n02730930', 'apron'],
        '412': ['n02747177', 'ashcan'],
        '413': ['n02749479', 'assault_rifle'],
        '414': ['n02769748', 'backpack'],
        '415': ['n02776631', 'bakery'],
        '416': ['n02777292', 'balance_beam'],
        '417': ['n02782093', 'balloon'],
        '418': ['n02783161', 'ballpoint'],
        '419': ['n02786058', 'Band_Aid'],
        '420': ['n02787622', 'banjo'],
        '421': ['n02788148', 'bannister'],
        '422': ['n02790996', 'barbell'],
        '423': ['n02791124', 'barber_chair'],
        '424': ['n02791270', 'barbershop'],
        '425': ['n02793495', 'barn'],
        '426': ['n02794156', 'barometer'],
        '427': ['n02795169', 'barrel'],
        '428': ['n02797295', 'barrow'],
        '429': ['n02799071', 'baseball'],
        '430': ['n02802426', 'basketball'],
        '431': ['n02804414', 'bassinet'],
        '432': ['n02804610', 'bassoon'],
        '433': ['n02807133', 'bathing_cap'],
        '434': ['n02808304', 'bath_towel'],
        '435': ['n02808440', 'bathtub'],
        '436': ['n02814533', 'beach_wagon'],
        '437': ['n02814860', 'beacon'],
        '438': ['n02815834', 'beaker'],
        '439': ['n02817516', 'bearskin'],
        '440': ['n02823428', 'beer_bottle'],
        '441': ['n02823750', 'beer_glass'],
        '442': ['n02825657', 'bell_cote'],
        '443': ['n02834397', 'bib'],
        '444': ['n02835271', 'bicycle-built-for-two'],
        '445': ['n02837789', 'bikini'],
        '446': ['n02840245', 'binder'],
        '447': ['n02841315', 'binoculars'],
        '448': ['n02843684', 'birdhouse'],
        '449': ['n02859443', 'boathouse'],
        '450': ['n02860847', 'bobsled'],
        '451': ['n02865351', 'bolo_tie'],
        '452': ['n02869837', 'bonnet'],
        '453': ['n02870880', 'bookcase'],
        '454': ['n02871525', 'bookshop'],
        '455': ['n02877765', 'bottlecap'],
        '456': ['n02879718', 'bow'],
        '457': ['n02883205', 'bow_tie'],
        '458': ['n02892201', 'brass'],
        '459': ['n02892767', 'brassiere'],
        '460': ['n02894605', 'breakwater'],
        '461': ['n02895154', 'breastplate'],
        '462': ['n02906734', 'broom'],
        '463': ['n02909870', 'bucket'],
        '464': ['n02910353', 'buckle'],
        '465': ['n02916936', 'bulletproof_vest'],
        '466': ['n02917067', 'bullet_train'],
        '467': ['n02927161', 'butcher_shop'],
        '468': ['n02930766', 'cab'],
        '469': ['n02939185', 'caldron'],
        '470': ['n02948072', 'candle'],
        '471': ['n02950826', 'cannon'],
        '472': ['n02951358', 'canoe'],
        '473': ['n02951585', 'can_opener'],
        '474': ['n02963159', 'cardigan'],
        '475': ['n02965783', 'car_mirror'],
        '476': ['n02966193', 'carousel'],
        '477': ['n02966687', 'carpenter\'s_kit'],
        '478': ['n02971356', 'carton'],
        '479': ['n02974003', 'car_wheel'],
        '480': ['n02977058', 'cash_machine'],
        '481': ['n02978881', 'cassette'],
        '482': ['n02979186', 'cassette_player'],
        '483': ['n02980441', 'castle'],
        '484': ['n02981792', 'catamaran'],
        '485': ['n02988304', 'CD_player'],
        '486': ['n02992211', 'cello'],
        '487': ['n02992529', 'cellular_telephone'],
        '488': ['n02999410', 'chain'],
        '489': ['n03000134', 'chainlink_fence'],
        '490': ['n03000247', 'chain_mail'],
        '491': ['n03000684', 'chain_saw'],
        '492': ['n03014705', 'chest'],
        '493': ['n03016953', 'chiffonier'],
        '494': ['n03017168', 'chime'],
        '495': ['n03018349', 'china_cabinet'],
        '496': ['n03026506', 'Christmas_stocking'],
        '497': ['n03028079', 'church'],
        '498': ['n03032252', 'cinema'],
        '499': ['n03041632', 'cleaver'],
        '500': ['n03042490', 'cliff_dwelling'],
        '501': ['n03045698', 'cloak'],
        '502': ['n03047690', 'clog'],
        '503': ['n03062245', 'cocktail_shaker'],
        '504': ['n03063599', 'coffee_mug'],
        '505': ['n03063689', 'coffeepot'],
        '506': ['n03065424', 'coil'],
        '507': ['n03075370', 'combination_lock'],
        '508': ['n03085013', 'computer_keyboard'],
        '509': ['n03089624', 'confectionery'],
        '510': ['n03095699', 'container_ship'],
        '511': ['n03100240', 'convertible'],
        '512': ['n03109150', 'corkscrew'],
        '513': ['n03110669', 'cornet'],
        '514': ['n03124043', 'cowboy_boot'],
        '515': ['n03124170', 'cowboy_hat'],
        '516': ['n03125729', 'cradle'],
        '517': ['n03126707', 'crane'],
        '518': ['n03127747', 'crash_helmet'],
        '519': ['n03127925', 'crate'],
        '520': ['n03131574', 'crib'],
        '521': ['n03133878', 'Crock_Pot'],
        '522': ['n03134739', 'croquet_ball'],
        '523': ['n03141823', 'crutch'],
        '524': ['n03146219', 'cuirass'],
        '525': ['n03160309', 'dam'],
        '526': ['n03179701', 'desk'],
        '527': ['n03180011', 'desktop_computer'],
        '528': ['n03187595', 'dial_telephone'],
        '529': ['n03188531', 'diaper'],
        '530': ['n03196217', 'digital_clock'],
        '531': ['n03197337', 'digital_watch'],
        '532': ['n03201208', 'dining_table'],
        '533': ['n03207743', 'dishrag'],
        '534': ['n03207941', 'dishwasher'],
        '535': ['n03208938', 'disk_brake'],
        '536': ['n03216828', 'dock'],
        '537': ['n03218198', 'dogsled'],
        '538': ['n03220513', 'dome'],
        '539': ['n03223299', 'doormat'],
        '540': ['n03240683', 'drilling_platform'],
        '541': ['n03249569', 'drum'],
        '542': ['n03250847', 'drumstick'],
        '543': ['n03255030', 'dumbbell'],
        '544': ['n03259280', 'Dutch_oven'],
        '545': ['n03271574', 'electric_fan'],
        '546': ['n03272010', 'electric_guitar'],
        '547': ['n03272562', 'electric_locomotive'],
        '548': ['n03290653', 'entertainment_center'],
        '549': ['n03291819', 'envelope'],
        '550': ['n03297495', 'espresso_maker'],
        '551': ['n03314780', 'face_powder'],
        '552': ['n03325584', 'feather_boa'],
        '553': ['n03337140', 'file'],
        '554': ['n03344393', 'fireboat'],
        '555': ['n03345487', 'fire_engine'],
        '556': ['n03347037', 'fire_screen'],
        '557': ['n03355925', 'flagpole'],
        '558': ['n03372029', 'flute'],
        '559': ['n03376595', 'folding_chair'],
        '560': ['n03379051', 'football_helmet'],
        '561': ['n03384352', 'forklift'],
        '562': ['n03388043', 'fountain'],
        '563': ['n03388183', 'fountain_pen'],
        '564': ['n03388549', 'four-poster'],
        '565': ['n03393912', 'freight_car'],
        '566': ['n03394916', 'French_horn'],
        '567': ['n03400231', 'frying_pan'],
        '568': ['n03404251', 'fur_coat'],
        '569': ['n03417042', 'garbage_truck'],
        '570': ['n03424325', 'gasmask'],
        '571': ['n03425413', 'gas_pump'],
        '572': ['n03443371', 'goblet'],
        '573': ['n03444034', 'go-kart'],
        '574': ['n03445777', 'golf_ball'],
        '575': ['n03445924', 'golfcart'],
        '576': ['n03447447', 'gondola'],
        '577': ['n03447721', 'gong'],
        '578': ['n03450230', 'gown'],
        '579': ['n03452741', 'grand_piano'],
        '580': ['n03457902', 'greenhouse'],
        '581': ['n03459775', 'grille'],
        '582': ['n03461385', 'grocery_store'],
        '583': ['n03467068', 'guillotine'],
        '584': ['n03476684', 'hair_slide'],
        '585': ['n03476991', 'hair_spray'],
        '586': ['n03478589', 'half_track'],
        '587': ['n03481172', 'hammer'],
        '588': ['n03482405', 'hamper'],
        '589': ['n03483316', 'hand_blower'],
        '590': ['n03485407', 'hand-held_computer'],
        '591': ['n03485794', 'handkerchief'],
        '592': ['n03492542', 'hard_disc'],
        '593': ['n03494278', 'harmonica'],
        '594': ['n03495258', 'harp'],
        '595': ['n03496892', 'harvester'],
        '596': ['n03498962', 'hatchet'],
        '597': ['n03527444', 'holster'],
        '598': ['n03529860', 'home_theater'],
        '599': ['n03530642', 'honeycomb']
    }
}
