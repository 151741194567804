<template>
    <div class="video-container" :class="{'paused': paused, 'recording': recordingRunning}">
        <toggle-button text2="Aufnahme starten" text1="Aufnahme läuft" icon1="radio_button_checked"
                       icon2="play_circle" text-color="var(--text-dark)"
                       bg-color="var(--primary-color)" v-on:click="toggleRec"
                       :state="recordingRunning"></toggle-button>
        <video controls muted disablePictureInPicture class="main-video" ref="video"
               :width="videoSize" :height="videoSize"
               :style="{borderColor: hasError ? 'var(--error-color)' : recordingRunning ? 'var(--primary-color)' : 'transparent'}"></video>
        <div v-if="hasError" class="error-box shadow">Kein Zugriff auf Kamera und Mikro!</div>
        <div v-if="saving" class="error-box shadow">Daten werden gespeichert!</div>
    </div>
</template>

<script>
    import ToggleButton from "./ToggleButton";
    import ClipRecorder from "../scripts/ClipRecorder";
    import DatabaseHandler from "../scripts/DatabaseHandler";

    export default {
        name: "video-clip",
        components: {ToggleButton},
        props: {
            videoSize: Number,
            label: String,
            tags: Array
        },
        data() {
            return {
                paused: false,
                controlsHidden: true,
                video: {},
                controls: {},
                clipRecorder: {},
                unsubscribeStore: () => {},
                recordingRunning: false,
                hasError: false,
                clipRecorderReady: false,
                dbHandler: {},
                saving: false
            }
        },
        created() {
            this.dbHandler = new DatabaseHandler('training_data', this.$store);

            this.saving = this.$store.state.savingRunning;
            if (this.$store.state.recordingRunning){
                this.$store.commit('toggleRecording', false);
            }
            if (!this.$store.state.sessionStarted) {
                this.$store.commit('toggleTraining', true);
            }

            // watch store
            this.unsubscribeStore = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'toggleRecording') {
                    this.recordingRunning = state.recordingRunning;
                }else if (mutation.type === 'toggleSaving') {
                    this.saving = state.savingRunning;
                    if (!this.recordingRunning && !this.saving){
                        this.stopTraining();
                        // this.$router.push('/results');
                        this.$router.push('/editing');
                    }
                }
            });
        },
        mounted() {
            this.setup();
            this.recordingRunning = this.$store.state.recordingRunning;
        },
        watch:{
            '$route' (to){
                if(to.path === '/'){
                    this.stopTraining();
                }
            }
        },
        methods: {
            setup: function() {
                this.clipRecorder = new ClipRecorder(this.$store, this.dbHandler, undefined);
                navigator.mediaDevices.getUserMedia(this.clipRecorder.constraintObj)
                    .then(async (mediaStreamObj) => {
                        this.clipRecorder.setMediaStream(mediaStreamObj);
                        this.video = this.$refs["video"];
                        this.controls = this.$refs["video-controls"];
                        if (this.video) {
                            this.clipRecorder.htmlVideo = this.video;
                            this.setMediaSrc(mediaStreamObj);
                            const videoWorks = !!document.createElement('video').canPlayType;
                            if (videoWorks) {
                                this.video.controls = false;
                            } else {
                                this.controlsHidden = false;
                            }
                            this.video.onloadedmetadata = () => {
                                //show in the video element what is being captured by the webcam
                                this.video.play();
                            };
                            // this.dbHandler.updateCurrentVideo(this.video);
                            // this.$store.commit('updateCurrentVideo', this.video);
                            this.clipRecorderReady = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.clipRecorder.hasError = true;
                        this.hasError = true;
                    });
            },
            toggleRec: async function () {
                if (this.$store.state.sessionStarted && !this.recordingRunning && this.clipRecorderReady){
                    this.$store.commit('toggleRecording', true);
                    await this.startRecording();
                }
            },
            stopTraining: function(){
                if (this.clipRecorderReady){
                    console.log('stopping video');
                    this.video.pause();
                    this.video = {};
                    // this.dbHandler.updateCurrentVideo({});
                    // this.$store.commit('updateCurrentVideo', {});
                }
            },
            startRecording: async function() {
                console.log('----start recording----');
                await this.clipRecorder.recordClip(this.label, this.tags);
            },
            setMediaSrc: function(mediaStream) {
                // Older browsers may not have srcObject
                if ('srcObject' in this.video) {
                    try {
                        this.video.srcObject = mediaStream;
                    } catch (err) {
                        if (err.name !== "TypeError") {
                            throw err;
                        }
                        // Even if they do, they may only support MediaStream
                        this.video.src = URL.createObjectURL(mediaStream);
                    }
                } else {
                    this.video.src = URL.createObjectURL(mediaStream);
                }
            }
        },
        unmounted() {
            this.unsubscribeStore();
        }
    }
</script>

<style scoped>

    .video-container {
        position: absolute;
        bottom: 1em;
        right: 4em;
        z-index: 1;
        display: grid;
        grid-template-rows: min-content min-content min-content;
        justify-items: center;
        grid-gap: 5px;
    }

    .main-video {
        border-radius: 4px;
        /*background-color: var(--background-dark);
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;*/
        border: 3px solid transparent;
    }


</style>
