import ClipData from "./ClipData";

export default class ClipRecorder {
    constructor(store, dbHandler, htmlVideo) {
        this.constraintObj = {
            audio: true,
            video: {
                facingMode: "user",
                width: store.state.imageSize,// width: {min: 640, ideal: 1280, max: 1920},
                height: store.state.imageSize,// height: {min: 480, ideal: 720, max: 1080}
            }
        };
        this.hasError = false;
        this.recordingTimeMS = store.state.recordingTime;
        this.store = store;
        this.dbHandler = dbHandler;
        this.htmlVideo = htmlVideo;
        this.mediaStream = {};
        this.initUserMedia();
        this.videoFrames = [];
        this.preview = '';  // Blob Url for preview image
        this.savePrev = true;
        this.audioFrames = [];
    }

    initUserMedia() {
        //handle older browsers that might implement getUserMedia in some way
        if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    devices.forEach(device => {
                        console.log(device.kind.toUpperCase(), device.label);
                        //, device.deviceId
                    })
                })
                .catch(err => {
                    console.log(err.name, err.message);
                });
        } else {
            navigator.mediaDevices.getUserMedia = (constraintObj) => {
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser or http is blocking'));
                }
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraintObj, resolve, reject);
                });
            }
        }
    }

    setMediaStream(stream) {
        this.mediaStream = stream;
    }

    async recordClip(label, tags) {
        if (!this.hasError && this.store.state.sessionStarted && this.store.state.recordingRunning) {
            let recordedChunks = await this.startRecording().catch((error) => {
                this.hasError = true;
                this.store.commit('toggleRecording', false);
                if (error.name === "NotFoundError") {
                    console.log("Camera or microphone not found. Can't record.");
                } else {
                    console.log(error);
                }
            });
            // has to be done first, to not leave the record page before saving
            this.store.commit('toggleSaving', true);
            this.store.commit('toggleRecording', false);
            await this.saveClip(recordedChunks, this.preview, label, tags);
        }
        this.stop();
    }

    async saveClip(recordedChunks, preview, label, tags){
        if (recordedChunks !== undefined) {

            let recordedBlob = new Blob(recordedChunks, {type: "video/mp4"});
            try {
                console.log('ClipRecorder: tags');
                console.log(tags);
                await this.dbHandler.addToQueue(new ClipData(recordedBlob, tags, label, '-', preview));
                await this.dbHandler.addVideoFrameData(this.videoFrames);
                // await this.saveAudioFrameData(recordedBlob);
                // await this.dbHandler.addAudioFrameData(this.audioFrames);

                // this.store.commit('addToQueue', new ClipData(recordedBlob, 'label', '3'));
                console.log("Successfully saved " + recordedBlob.size + " bytes of " +
                    recordedBlob.type + " media.");
                this.store.commit('toggleSaving', false);
            }catch (e) {
                this.store.commit('toggleSaving', false);
            }
        }
    }

    startRecording() {
        let recorder = new MediaRecorder(this.mediaStream);
        let videoBlobs = [];
        this.videoFrames = [];
        this.audioFrames = [];
        this.savePrev = true;

        recorder.ondataavailable = (event) => {
            // const start = performance.now();
            videoBlobs.push(event.data);
            this.saveVideoFrameData();
                // .then(() => console.log('video frame data added in: ' + (performance.now() - start) + 'ms'));

        };
        recorder.start(84); // number of ms for each chunk
        console.log(recorder.state + " for " + (this.recordingTimeMS / 1000) + " seconds...");

        let stopped = new Promise((resolve, reject) => {
            recorder.onstop = resolve;
            recorder.onerror = event => reject(event.name);
        });

        let recorded = this.wait(this.recordingTimeMS).then(
            () => recorder.state === "recording" && recorder.stop()
        );

        return Promise.all([
            stopped,
            recorded
        ]).then(() => videoBlobs);
    }

    stop() {
        this.mediaStream.getTracks().forEach(track => track.stop());
    }

    wait(delayInMS) {
        return new Promise(resolve => setTimeout(resolve, delayInMS));
    }

    async saveVideoFrameData() {
        // generate model input
        let canvas = document.createElement("canvas");
        canvas.width = this.store.state.imageSize || 224;
        canvas.height = this.store.state.imageSize || 224;
        let canvasContext = canvas.getContext("2d");

        try {
            if (this.htmlVideo !== undefined) {
                canvasContext.drawImage(this.htmlVideo, 0, 0, canvas.width, canvas.height);
                let frame = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
                // save first image url as preview
                if (this.savePrev){
                    canvas.toBlob((blob) => {
                        this.preview = URL.createObjectURL(blob);
                        console.log('preview url: ' + this.preview);
                    });
                    this.savePrev = false;
                }
                this.videoFrames.push(frame.data);
                // console.log('frame added');
            } else {
                console.log('no valid video src');
            }
        } catch (error) {
            console.log(`could not add video frame: ${error}`);
        }
    }

    // format: non-interleaved IEEE754 32-bit linear PCM with a nominal range between -1 and +1,
    // that is, a 32-bit floating point buffer, with each sample between -1.0 and 1.0.
    // If the AudioBuffer has multiple channels, they are stored in separate buffers.
    async saveAudioFrameData(blob) {
        let audioCtx = new (window.AudioContext || window.webkitAudioContext);
        let audioData = [];
        // let source; // for playing the audio

        let audioBuffer = await blob.arrayBuffer().catch(() => {
            console.log('could not read audio data');
        });

        if (audioCtx !== undefined && audioBuffer !== undefined) {
            // source = audioCtx.createBufferSource();
            await audioCtx.decodeAudioData(audioBuffer, function (buffer) {
                console.log('audio data: ');
                console.log(buffer);
                audioData = buffer;
                // source.buffer = buffer;
                // source.connect(audioCtx.destination);
            }, function (e) {
                console.log('could not add audio frame: ' + e.error);
            });
            this.audioFrames = audioData;
        } else {
            console.log('audio not available!');
        }
    }

}
