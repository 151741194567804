import {imageDataToTensor} from "./DataUtils";
import {Tensor} from "onnxruntime-web";

let ndarray = require("ndarray");
let ops = require("ndarray-ops");

/**
 * Prepares Data for the ResNet50 Model
 */
export default class ResNet50Loader {
    constructor(modelUrl, dbHandler) {
        this.dbHandler = dbHandler;
        this.frameSize = 224;
        this.modelUrl = modelUrl;
    }

    async loadImage() {
        const start = performance.now();
        let frame = await this.dbHandler.getVideoFrameData(0);
        // generate model input
        if (frame !== undefined) {
            console.log('image loaded in: ' + (performance.now() - start) + 'ms');
            let dataArray = imageDataToTensor(frame.imgArray, this.frameSize);
            console.log(dataArray);
            return new Tensor("float32", dataArray, [1, 3, this.frameSize, this.frameSize]);
        }
        return undefined;
    }

    /**
     * Preprocess raw image data to match Resnet50 requirement.
     */
    preprocess(data) {
        const dataFromImage = ndarray(new Float32Array(data), [this.frameSize, this.frameSize, 4]);
        const dataProcessed = ndarray(new Float32Array(this.frameSize * this.frameSize * 3), [1, 3, this.frameSize, this.frameSize]);

        // Normalize 0-255 to (-1)-1
        ops.divseq(dataFromImage, 128.0);
        ops.subseq(dataFromImage, 1.0);

        // Realign imageData from [224*224*4] to the correct dimension [1*3*224*224].
        ops.assign(dataProcessed.pick(0, 0, null, null), dataFromImage.pick(null, null, 2));
        ops.assign(dataProcessed.pick(0, 1, null, null), dataFromImage.pick(null, null, 1));
        ops.assign(dataProcessed.pick(0, 2, null, null), dataFromImage.pick(null, null, 0));
        // console.log(dataProcessed.data);
        return dataProcessed.data;
    }

}
